<template>
  <v-container fluid class="container">
    <p class="text-h5 mb-4">API Интегрированное с SMSBOX</p>

    <v-expansion-panels v-model="panel" multiple>
      <v-expansion-panel class="pb-0 mb-4" elevation="1">
        <v-expansion-panel-title><span class="font-weight-medium text-body-1 mb-n1">1 – Запрос количества доступных номеров</span></v-expansion-panel-title>
        <v-expansion-panel-text class="pb-0 mb-0">
          <p class="code-style-box">smsbox.store/stubs/handler_api.php?api_key=$api_key&action=getNumbersStatus&country=$country&operator=$operator</p>
          <p>Параметры:</p>
          <ul>
            <li>$api_key - ключ API</li>
            <li>$country - страна номера (0 - Россия, по умолчанию)</li>
            <li>$operator - сотовый оператор номера (mts, tele2, megafon, beeline, any)</li>
          </ul>
          <p>Ответ в формате JSON:</p>
          <p class="code-style-box">{"vk_0":57,"ok_0":59,"wa_0":240,"vi_0":3,"tg_0":79,"wb_0":119,"go_0":193,"av_0":24,"av_1":237,"fb_0":107}</p>
          <p>Где:</p>
          <ul>
            <li>До черточки - название сервиса</li>
            <li>После черточки - нужно ли включать переадресацию (0 - нет, 1 - да)</li>
          </ul>
        </v-expansion-panel-text>
      </v-expansion-panel>

      <v-expansion-panel class="pb-0 mb-4" elevation="1">
        <v-expansion-panel-title><span class="font-weight-medium text-body-1 mb-n1">2 – Запрос баланса</span></v-expansion-panel-title>
        <v-expansion-panel-text class="pb-0 mb-0">
          <p class="code-style-box">smsbox.store/stubs/handler_api.php?api_key=$api_key&action=getBalance</p>
          <p>Параметры:</p>
          <ul>
            <li>$api_key - ключ API</li>
          </ul>
          <p>Ответ:</p>
          <p class="code-style-box">ACCESS_BALANCE:$balance (где $balance - баланс на счету)</p>
          <p>Ошибки:</p>
          <ul>
            <li>BAD_KEY - Неверный API-ключ</li>
          </ul>
        </v-expansion-panel-text>
      </v-expansion-panel>

      <v-expansion-panel class="pb-0 mb-4" elevation="1">
        <v-expansion-panel-title><span class="font-weight-medium text-body-1 mb-n1">3 – Заказ номера</span></v-expansion-panel-title>
        <v-expansion-panel-text class="pb-0 mb-0">
          <p class="code-style-box">smsbox.store/stubs/handler_api.php?api_key=$api_key&action=getNumber&service=$service&forward=$forward&operator=$operator&country=$country</p>
          <p>Параметры:</p>
          <ul>
            <li>$api_key - ключ API</li>
            <li>$service - сервис для заказа</li>
            <li>$operator - оператор (mts, tele2, megafon, beeline, any)</li>
            <li>$country - страна номера</li>
          </ul>
          <p>Список стран:</p>
          <v-virtual-scroll
            :height="300"
            :items="countries"
            class="pa-2"
            style="border: 2px grey solid; border-radius: 10px; border-top-right-radius: 0; border-end-end-radius: 0;"
          >
            <template v-slot:default="{ item }">
              {{ item }}
            </template>
          </v-virtual-scroll>
        </v-expansion-panel-text>
      </v-expansion-panel>

      <v-expansion-panel class="pb-0 mb-4" elevation="1">
        <v-expansion-panel-title><span class="font-weight-medium text-body-1 mb-n1">4 – Изменение статуса активации</span></v-expansion-panel-title>
        <v-expansion-panel-text class="pb-0 mb-0">
          <p class="code-style-box">smsbox.store/stubs/handler_api.php?api_key=$api_key&action=setStatus&status=$status&id=$id&forward=$forward</p>
          <p>Параметры:</p>
          <ul>
            <li>$api_key - ключ API</li>
            <li>$id - id активации</li>
            <li>$forward - номер для переадресации (обязательно, если при getNumber был параметр forward=1)</li>
            <li>$status - статус активации:</li>
            <ul>
              <li>-1 - отменить активацию</li>
              <li>1 - сообщить о готовности номера</li>
              <li>3 - запросить еще один код (бесплатно)</li>
              <li>6 - завершить активацию</li>
              <li>8 - сообщить о том, что номер использован и отменить активацию</li>
            </ul>
          </ul>
          <p>Ответы сервиса:</p>
          <ul>
            <li>ACCESS_READY - готовность номера подтверждена</li>
            <li>ACCESS_RETRY_GET - ожидание нового смс</li>
            <li>ACCESS_ACTIVATION - сервис успешно активирован</li>
            <li>ACCESS_CANCEL - активация отменена</li>
          </ul>
          <p>Ошибки:</p>
          <ul>
            <li>ERROR_SQL - ошибка SQL-сервера</li>
            <li>NO_ACTIVATION - id активации не существует</li>
            <li>BAD_SERVICE - некорректное наименование сервиса</li>
            <li>BAD_STATUS - некорректный статус</li>
            <li>BAD_KEY - Неверный API-ключ</li>
            <li>BAD_ACTION - некорректное действие</li>
          </ul>
        </v-expansion-panel-text>
      </v-expansion-panel>

      <v-expansion-panel class="pb-0 mb-4" elevation="1">
        <v-expansion-panel-title><span class="font-weight-medium text-body-1 mb-n1">5 – Получить состояние активации</span></v-expansion-panel-title>
        <v-expansion-panel-text class="pb-0 mb-0">
          <p class="code-style-box">smsbox.store/stubs/handler_api.php?api_key=$api_key&action=getStatus&id=$id</p>
          <p>Параметры:</p>
          <ul>
            <li>$api_key - ключ API</li>
            <li>$id - id активации</li>
          </ul>
          <p>Ответы сервиса:</p>
          <ul>
            <li>STATUS_WAIT_CODE - ожидание смс</li>
            <li>STATUS_WAIT_RETRY:$lastcode - ожидание уточнения кода</li>
            <li>STATUS_WAIT_RESEND - ожидание повторной отправки смс</li>
            <li>STATUS_CANCEL - активация отменена</li>
            <li>STATUS_OK:$code - код получен</li>
          </ul>
          <p>Ошибки:</p>
          <ul>
            <li>ERROR_SQL - ошибка SQL-сервера</li>
            <li>NO_ACTIVATION - id активации не существует</li>
            <li>BAD_SERVICE - некорректное наименование сервиса</li>
            <li>BAD_STATUS - некорректный статус</li>
            <li>BAD_KEY - Неверный API-ключ</li>
            <li>BAD_ACTION - некорректное действие</li>
          </ul>
        </v-expansion-panel-text>
      </v-expansion-panel>

      <v-expansion-panel class="pb-0" elevation="1">
        <v-expansion-panel-title><span class="font-weight-medium text-body-1 mb-n1">6 – Актуальные цены по всем странам</span></v-expansion-panel-title>
        <v-expansion-panel-text class="pb-0 mb-0">
          <p class="code-style-box">smsbox.store/stubs/handler_api.php?api_key=$api_key&action=getPrices&service=$service&country=$country</p>
          <p>Параметры:</p>
          <ul>
            <li>$api_key - ключ API</li>
            <li>$service - наименование сервиса (необязательно)</li>
            <li>$country - код страны (необязательно)</li>
          </ul>
          <p>Ответ в формате JSON:</p>
          <p class="code-style-box">{"Страна":{"Сервис":{"cost":Стоимость,"count":Количество}}}</p>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
    
    <!-- <p class="code-style-box">
      https://smsbox.store/stubs/handler_api.php?api_key=$api_key&action=getNumbersStatus&country=$country<br /><br />

      Параметры:<br />
      $api_key = ВАШ_API_KEY<br />
      $country = параметр не играет роли<br /><br />

      Ответ сервиса в json формате:<br />
      {"vk_0":"0","ig_0":"3","ok_0":"1","vi_0":"3","other_0":"2"}<br /><br />

      Где до черточки - название сервиса, после обозначение нужно ли включать
      переадресацию. 0 - не включать. 1 - включать. Переадресации пока нет<br />
      Возможные ошибки:<br />
      BAD_KEY - неверный API-ключ<br />
      ERROR_SQL - ошибка SQL-сервера<br /><br />

      Пример:<br />
      https://smsbox.store/stubs/handler_api.php?api_key=f8d597bb7439df66a4eeb5befdee6e44&action=getNumbersStatus&country=$country
    </p>

    <p class="text-h6">Другие методы api по такому-же принципу</p> -->
  </v-container>
</template>

<script>
import * as fun from '@/functions.js'

export default {
  name: 'Api',
  data() {
    return {
      panel: [],
      countries: [
        "143 - Tajikistan",
        "96 - Zimbabwe",
        "35 - Azerbaijan",
        "9 - Tanzania",
        "12 - United States Virtual",
        "94 - Guatemala",
        "141 - Slovakia",
        "21 - Egypt",
        "77 - Cyprus",
        "203 - Kosovo",
        "137 - Malawi",
        "91 - Timor-Leste",
        "45 - Croatia",
        "66 - Pakistan",
        "11 - Kyrgyzstan",
        "129 - Greece",
        "13 - Israel",
        "40 - Uzbekistan",
        "39 - Argentina",
        "50 - Austria",
        "60 - Bangladesh",
        "63 - Czech Republic",
        "31 - South Africa",
        "76 - Angola",
        "36 - Canada Virtual",
        "59 - Slovenia",
        "1 - Ukraine",
        "187 - United States",
        "80 - Mozambique",
        "10 - Vietnam",
        "56 - Spain",
        "48 - Netherlands",
        "172 - Denmark",
        "34 - Estonia",
        "16 - United Kingdom",
        "2 - Kazakhstan",
        "32 - Romania",
        "83 - Bulgaria",
        "54 - Mexico",
        "14 - Hong Kong",
        "6 - Indonesia",
        "49 - Latvia",
        "15 - Poland",
        "25 - Laos",
        "4 - Phillipines",
        "128 - Georgia",
        "78 - France",
        "163 - Finland",
        "43 - Germany",
        "44 - Lithuania",
        "8 - Kenya",
        "52 - Thailand",
        "7 - Malaysia",
        "85 - Moldova",
        "37 - Morocco",
        "117 - Portugal",
        "46 - Sweden",
      ],
    }
  },
}
</script>

<style scoped>
/* .container p {
  margin-bottom: 10px;
}
.container p:last-child {
  margin-bottom: 0;
} */
.container *{
  margin-bottom: 5px;
}
.container *:last-child{
  margin-bottom: 0;
}
.code-style-box {
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  font-family: monospace;
  font-size: 14px;
  line-height: 1.5;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-all;
  overflow-wrap: break-word;
  -ms-word-break: break-all;
  -ms-word-wrap: break-word;
  word-break: break-word;
  word-break: break-all;
  word-wrap: break-word;
  overflow-wrap: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
}
.bigger-text {
  font-size: 18px;
}
ul{
  margin-left: 20px;
}
</style>