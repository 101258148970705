<template>
    <v-container fluid :class="`mx-0 ${xs ? 'px-0' : ''}`">
        <div style="z-index: 1;">
            <v-row :class="`d-flex flex-row justify-center flex-wrap ${xs ? 'px-2' : ''}`">
                <v-col :class="`d-flex flex-column ${lgAndUp ? 'align-end' : 'align-center'}`" v-if="role_route == '/' && (mdAndDown ? current_path == '/' : true)" xl="3" :lg="4" :md="6" sm="10" :xs="phones">
                    <Services @update_user="$emit('update_user')"></Services>
                </v-col>
                <v-col :class="[
                    'd-flex flex-column',
                    role_route != '/auth' ? 'bg-white elevation-3 rounded-lg pa-5 pt-3' : '',
                    'my-3',
                    xs ? 'px-0' : '',
                    ]" :lg="is_full_screen ? 11 : 7" :md="11" :sm="10" :xs="phones">
                    <slot></slot>
                </v-col>
            </v-row>
        </div>
    </v-container>
</template>

<script setup>
import { useDisplay } from 'vuetify'

// Destructure only the keys you want to use
const { xs, sm, smAndDown, smAndUp, md, mdAndDown, mdAndUp, lg, lgAndDown, lgAndUp, xl, xlAndDown, xlAndUp, xxl } = useDisplay();
</script>

<script>
import * as fun from '@/functions.js'
import Services from '@/components/Services.vue'

export default {
    name: 'ContentWrapper',
    props: {
        phones: {
            type: Number,
            default: 12
        },
    },
    components: {
        Services,
    },
    computed: {
        is_full_screen(){
            return this.role_route == '/admin' || this.role_route == '/partner';
        },
        current_path(){
            return this.$route.path;
        },
        role_route(){
            let route = "/";
            if(this.current_path.includes('/admin')) route = '/admin';
            if(this.current_path.includes('/partner')) route = '/partner';
            if(this.current_path.includes('/auth')) route = '/auth';
            return route;
        },
    }
};
</script>

<style scoped>
</style>