import { createRouter, createWebHistory } from 'vue-router'

// component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
const title_ending = " – Smsbox";

import AdminMain from '@/views/admin/Main.vue'
import AdminUsers from '@/views/admin/Users.vue'
import AdminServices from '@/views/admin/Services.vue'
import AdminCountries from '@/views/admin/Countries.vue'
import AdminOrders from '@/views/admin/Orders.vue'
import AdminOperators from '@/views/admin/Operators.vue'
import AdminNews from '@/views/admin/News.vue'
import AdminPayout from '@/views/admin/Payout.vue'
const admin_routes = [
  {
    path: '/admin/',
    name: 'Главная Админ-панель',
    component: AdminMain
  },
  {
    path: '/admin/users',
    name: 'Пользователи Админ-панель',
    component: AdminUsers
  },
  {
    path: '/admin/services',
    name: 'Сервисы Админ-панель',
    component: AdminServices
  },
  {
    path: '/admin/countries',
    name: 'Страны Админ-панель',
    component: AdminCountries
  },
  {
    path: '/admin/orders',
    name: 'Заказы Админ-панель',
    component: AdminOrders
  },
  {
    path: '/admin/operators',
    name: 'Операторы Админ-панель',
    component: AdminOperators
  },
  {
    path: '/admin/news',
    name: 'Новости Админ-панель',
    component: AdminNews
  },
  {
    path: '/admin/payout',
    name: 'Выплаты Админ-панель',
    component: AdminPayout
  },
]

import SignIn from '@/views/auth/SignIn.vue'
import SignUp from '@/views/auth/SignUp.vue'
import ConfirmEmail from '@/views/auth/ConfirmEmail.vue'
import ForgotPassword from '@/views/auth/ForgotPassword.vue'
const auth_routes = [
  {
    path: '/auth/signin',
    name: 'Войти в аккаунт',
    component: SignIn
  },
  {
    path: '/auth/signup',
    name: 'Зарегистрироваться',
    component: SignUp
  },
  {
    path: '/auth/confirm-email',
    name: 'Подтвердить email',
    component: ConfirmEmail
  },
  {
    path: '/auth/forgot-password',
    name: 'Восстановить пароль',
    component: ForgotPassword
  },
]

import UserMain from '@/views/user/Main.vue'
import UserOrders from '@/views/user/Orders.vue'
import UserApi from '@/views/user/Api.vue'
import UserSettings from '@/views/user/Settings.vue'
import UserOffer from '@/views/user/Offer.vue'
import UserReferral from '@/views/user/Referral.vue'
import UserBalance from '@/views/user/Balance.vue'
import UserNews from '@/views/user/News.vue'
const user_routes = [
  {
    path: '/',
    name: 'Главная',
    component: UserMain
  },
  {
    path: '/orders',
    name: 'Заказы',
    component: UserOrders
  },
  {
    path: '/api',
    name: 'API',
    component: UserApi
  },
  {
    path: '/settings',
    name: 'Настройки',
    component: UserSettings
  },
  {
    path: '/offer',
    name: 'Договор-оферта',
    component: UserOffer
  },
  {
    path: '/referral-program',
    name: 'Реферальная система',
    component: UserReferral
  },
  {
    path: '/balance',
    name: 'Баланс',
    component: UserBalance
  },
  {
    path: '/news',
    name: 'Информация',
    component: UserNews
  },
]

import PartnerAgents from '@/views/partner/Agents.vue'
import PartnerServices from '@/views/partner/Services.vue'
import PartnerHistory from '@/views/partner/History.vue'
import PartnerFinances from '@/views/partner/Finances.vue'
const partner_routes = [
  {
    path: '/partner',
    name: 'Агенты Партнерский ЛК',
    component: PartnerAgents
  },
  {
    path: '/partner/services',
    name: 'Сервисы Партнерский ЛК',
    component: PartnerServices
  },
  {
    path: '/partner/history',
    name: 'История Партнерский ЛК',
    component: PartnerHistory
  },
  {
    path: '/partner/finances',
    name: 'Финансы Партнерский ЛК',
    component: PartnerFinances
  },
]

import NotFound from '@/views/user/404.vue'
const error_routes = [
  {
    path: '/:pathMatch(.*)*',
    name: 'Страница не найдена',
    component: NotFound
  },
]

const routes = [...admin_routes, ...auth_routes, ...user_routes, ...partner_routes, ...error_routes];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  document.title = `${String(to.name)}${title_ending}`;
  next();
});

export default router
