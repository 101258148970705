<template>
  <v-btn block color="primary" elevation="0" prependIcon="mdi-plus" variant="text" class="mb-5" @click="add.data.targetRoles = [1, 2, 3]; add.overlay = true;">Добавить новость</v-btn>
  <v-data-table-server
    v-model:items-per-page="itemsPerPage"
    :headers="headers"
    :items="serverItems"
    :items-length="totalItems"
    :loading="loading"
    :search="search"
    loading-text="Загружаем данные..."
    item-value="name"
    @update:options="loadItems"
  >
    <template v-slot:item.balance="{ item }">
      <p>{{ item.balance }} ₽</p>
    </template>
    <template v-slot:item.description="{ item }">
      <p>{{ item.description.substr(0, 100) + (item.description.length > 100 ? '...' : '' ) }}</p>
    </template>
    <template v-slot:item.targetRoles="{ item }">
      <p><span v-for="(it, index) in item.targetRoles" :key="index">{{roleNumToName(it)}}{{ index + 1 == item.targetRoles.length ? '' : ', ' }}</span></p>
    </template>
    <template v-slot:item.isBlocked="{ item }">
      <v-chip
        :color="item.isBlocked ? 'red' : 'green'"
        :text="item.isBlocked ? 'Да' : 'Нет'"
        class="text-uppercase"
        size="small"
        label
      ></v-chip>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-btn color="orange" variant="tonal" prepend-icon="mdi-pencil" class="ma-1" @click="open_edit_form(item.id)">Редактировать</v-btn>
      <v-btn color="red" variant="tonal" prepend-icon="mdi-delete" class="ma-1" @click="delete_service(item.id)">Удалить</v-btn>
    </template>
  </v-data-table-server>

  <v-overlay
    v-model="add.overlay"
    class="align-center justify-center"
  >
    <Card title="Добавить новость" @closed="close_add_form();" @confirmed="add_service()" style="min-width: 800px;">
      <v-md-editor v-model="add.data.description" height="400px" lang="ru"></v-md-editor>
      <v-text-field label="Заголовок новости" v-model="add.data.title" minlength="2" class="mt-4"></v-text-field>
      <p class="text-body-1 mt-n2">Эта новость будет видна:</p>
      <div class="ml-n2 mt-n2">
        <v-checkbox
          v-model="add.data.targetRoles"
          label="Обычному пользователю"
          :value="1"
          class="mb-n10"
        ></v-checkbox>
        <v-checkbox
          v-model="add.data.targetRoles"
          label="Партнеру"
          :value="2"
          class="mb-n10"
        ></v-checkbox>
        <v-checkbox
          v-model="add.data.targetRoles"
          label="Агенту"
          :value="3"
          class="mb-n10"
        ></v-checkbox>
      </div>
    </Card>
  </v-overlay>

  <v-overlay
    v-model="edit.overlay"
    class="align-center justify-center"
  >
    <Card title="Редактировать новость" @closed="close_edit_form();" @confirmed="edit_service()" style="min-width: 800px;">
      <v-md-editor v-model="edit.data.description" height="400px" lang="ru"></v-md-editor>
      <v-text-field label="Заголовок новости" v-model="edit.data.title" minlength="2" class="mt-4"></v-text-field>
      <p class="text-body-1 mt-n2">Эта новость будет видна:</p>
      <div class="ml-n2 mt-n2">
        <v-checkbox
          v-model="edit.data.targetRoles"
          label="Обычному пользователю"
          :value="1"
          class="mb-n10"
        ></v-checkbox>
        <v-checkbox
          v-model="edit.data.targetRoles"
          label="Партнеру"
          :value="2"
          class="mb-n10"
        ></v-checkbox>
        <v-checkbox
          v-model="edit.data.targetRoles"
          label="Агенту"
          :value="3"
          class="mb-n10"
        ></v-checkbox>
      </div>
    </Card>
  </v-overlay>

  <v-overlay
    v-model="circle_loading"
    class="align-center justify-center"
    persistent
  >
    <v-progress-circular
      :size="50"
      color="primary"
      indeterminate
    ></v-progress-circular>
  </v-overlay>
</template>

<script>
import * as fun from '@/functions.js'
import Card from '@/components/Card.vue'

export default {
  name: 'AdminNews',
  components: {
    Card,
  },
  data() {
    return {
      itemsPerPage: 10,
      headers: [
        { title: 'ID', key: 'id' },
        { title: 'Заголовок', value: 'title' },
        { title: 'Текст новости', value: 'description' },
        { title: 'Роли', value: 'targetRoles' },
        { title: 'Действия', value: 'actions' },
      ],
      serverItems: [],
      loading: true,
      totalItems: 0,
      add: {
        overlay: false,
        data: {},
      },
      edit: {
        overlay: false,
        data: {},
      },
    }
  },
  methods: {
    loadItems({ page, itemsPerPage, sortBy } = {page: 1, itemsPerPage: this.itemsPerPage}){
      this.loading = true;
      this.axios({
        method: 'get',
        url: `${fun.SERVER_URL}/News`,
        params: {
          pageNumber: page,
          pageSize: itemsPerPage,
        },
      }).then((response) => {
        this.serverItems = response.data.items;
        this.totalItems = response.data.totalCount;
        this.loading = false;
      }).catch((error) => {
        fun.show(this, "Произошла ошибка при загрузке данных")
        this.loading = false;
      });
    },
    delete_service(id){
      if(confirm("Are you sure you want to delete news - ID " + id + "?")){
        this.loading = true;
        this.axios({
          method: 'delete',
          url: `${fun.SERVER_URL}/News/${id}`,
        }).then((response) => {
          this.loadItems();
          this.loading = false;
        }).catch((error) => {
          fun.show(this, "Произошла ошибка")
          this.loading = false;
        });
      }
    },
    add_service(){
      this.circle_loading = true;
      let data = this.add.data;

      this.axios({
        method: 'post',
        url: `${fun.SERVER_URL}/News`,
        data: data,
      }).then((response) => {
        let final_obj = this.add.data;
        final_obj.id = Number(response.data);

        this.loadItems();
        this.add.overlay = false;
        this.add.data = {};
        this.circle_loading = false;
      }).catch((error) => {
        console.error(error);
        fun.show(this, "Ошибка при отправке формы")
        this.circle_loading = false;
      });
    },
    edit_service(){
      this.circle_loading = true;
      let data = this.edit.data;

      this.axios({
        method: 'put',
        url: `${fun.SERVER_URL}/News/${this.edit.data.id}`,
        data: data,
      }).then((response) => {
        let final_obj = this.edit.data;

        for(let i = 0; i < this.serverItems.length; i++){
          if(this.serverItems[i].id == this.edit.data.id){
            this.serverItems[i] = final_obj;
            break;
          }
        }
        this.edit.overlay = false;
        this.edit.data = {};
        this.circle_loading = false;
      }).catch((error) => {
        console.log(error);
        fun.show(this, "Ошибка при отправке формы")
        this.circle_loading = false;
      });
    },

    close_add_form(){
      this.add.data = {};
      this.add.overlay = false;
    },
    open_edit_form(id){
      for(let i = 0; i < this.serverItems.length; i++){
        if(this.serverItems[i].id == id){
          this.edit.data = {};
          const keys = Object.keys(this.serverItems[i]);
          console.log( this.serverItems[i]);
          for(let j = 0; j < keys.length; j++){
            this.edit.data[keys[j]] = this.serverItems[i][keys[j]];
          }
          this.edit.overlay = true;
          break;
        }
      }
    },
    close_edit_form(){
      this.edit.data = {};
      this.edit.overlay = false;
    },
    roleNumToName(n){
      if(n == 1){
        return "Обычному пользователю";
      } else if(n == 2){
        return "Партнеру";
      } else if(n == 3){
        return "Агенту";
      } else{
        return "Неизвестно";
      }
    },
  },
  mounted(){
  },
}
</script>