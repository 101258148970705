<template>
    <v-container fluid class="d-flex flex-row justify-center">
        <v-form
        @submit.prevent="signup"
        class="bg-surface pa-6 pb-3 rounded elevation-3" :style="`width: ${xs ? '90vw' : '450px'};`" elevation-10>
            <p class="text-h5 mb-4">Создать аккаунт</p>
            <v-text-field v-model="email" label="Email" type="email" required :rules="[v => !!v || 'Email обязателен', v => /.+@.+\..+/.test(v) || 'Email должен быть валидным']"></v-text-field>
            <v-text-field v-model="password" label="Пароль" type="password" required :rules="[
                v => !!v || 'Пароль обязателен',
                v => (v && v.length >= 6) || 'Пароль должен содержать не менее 6 символов',
                v => /\d/.test(v) || 'Пароль должен содержать хотя бы одну цифру',
                v => /[a-z]/.test(v) || 'Пароль должен содержать хотя бы одну прописную латинскую букву',
                v => /[A-Z]/.test(v) || 'Пароль должен содержать хотя бы одну заглавную латинскую букву',
                v => /\W/.test(v) || 'Пароль должен содержать хотя бы один символ, не являющийся буквой или цифрой',
            ]"></v-text-field>
            <v-text-field v-model="password2" label="Повторите пароль" type="password" required :rules="[
                v => !!v || 'Подтверждение пароля обязательно',
                v => (v == password) || 'Пароли должны совпадать',
            ]"></v-text-field>
            <v-btn type="submit" color="primary" block :disabled="is_btn_disabled || button_disabled" >Регистрация</v-btn>

            <v-container class="d-flex flex-column align-center" fluid>
                <p class="text-body-1 text-grey-darken-2" style="text-align: center;">Нажимая Регистрация, вы принимаете нашу
                    <a href="https://www.termsfeed.com/live/c4e7fc08-4dc3-49fd-9db1-0a52151f7989" target="_blank" style="color: #825bf3; text-decoration: none;">политику конфиденциальности</a>,
                    <router-link to="/offer" style="color: #825bf3; text-decoration: none;">договор оферту</router-link> и
                    <a href="https://www.termsfeed.com/live/70ded395-9704-4d6a-abd3-a02e7db9ec0a" target="_blank" style="color: #825bf3; text-decoration: none;">политику куки файлов</a>
                </p>
                <v-container class="my-3 py-0" fluid>
                    <v-divider></v-divider>
                </v-container>
                <p>Уже есть аккаунт? <router-link to="/auth/signin" style="color: #825bf3; text-decoration: none;">Войти</router-link></p>
            </v-container>
        </v-form>
    </v-container>
</template>

<script setup>
import { useDisplay } from 'vuetify'

// Destructure only the keys you want to use
const { xs, sm, smAndDown, smAndUp, md, mdAndDown, mdAndUp, lg, lgAndDown, lgAndUp, xl, xlAndDown, xlAndUp, xxl } = useDisplay();
</script>

<script>
import * as fun from '@/functions.js'

export default {
    name: 'SignUp',
    data() {
        return {
            email: '',
            password: '',
            password2: '',
            button_disabled: false,
        };
    },
    methods: {
        signup() {
            this.button_disabled = true;
            this.axios({
                method: 'post',
                url: `${fun.SERVER_URL}/Users/register`,
                data: {
                    email: this.email,
                    password: this.password,
                }
            }).then((response) => {
                // fun.show(this, "Успешная регистрация", true);

                // ------ вход в аккаунт и подтверждение почты

                // this.$router.push('/auth/signin');
                this.axios({
                    method: 'post',
                    url: `${fun.SERVER_URL}/Users/login`,
                    data: {
                        email: this.email,
                        password: this.password,
                    }
                }).then((response) => {
                    this.button_disabled = false;

                    let token_obj = response.data;
                    token_obj.updatedAt = new Date().getTime();
                    this.$cookies.set('token', JSON.stringify(token_obj));
                    this.axios.defaults.headers['Authorization'] = `${token_obj.tokenType} ${token_obj.accessToken}`;

                    this.axios({
                        method: 'get',
                        url: `${fun.SERVER_URL}/Users/info`,
                    }).then((response) => {
                        this.userObject = response.data;
                        this.$cookies.set('user', JSON.stringify(response.data));

                        this.$router.push('/auth/confirm-email');
                    }).catch((error) => {
                        console.log(error);
                    });

                }).catch((error) => {
                    this.button_disabled = false;
                    console.log(error)
                    fun.show(this, 'Произошла неизвестная ошибка');
                    this.$router.push('/auth/signin');
                });

            }).catch((error) => {
                    console.log(error)
                this.button_disabled = false;
                fun.show(this, (error.response && error.response.data && error.response.data.errors && Object.keys(error.response.data.errors).length > 0) ? error.response.data.errors[Object.keys(error.response.data.errors)[0]][0] : 'Произошла неизвестная ошибка');
            });
        }
    },
    computed: {
        is_btn_disabled(){
            let result = this.email == '' || this.password == '' || this.password2 == '';
            if(!result){
                let v = this.password;
                let password_correct = (v && v.length >= 6) && /\d/.test(v) && /[a-z]/.test(v) && /[A-Z]/.test(v) && /\W/.test(v);
                let passwords_match = this.password == this.password2;
                let email_correct = /.+@.+\..+/.test(this.email);
                result = !password_correct || !passwords_match || !email_correct;
            }
            return result;
        }
    },
    mounted() {
    },
};
</script>