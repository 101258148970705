<template>
  <v-btn block color="primary" elevation="0" prependIcon="mdi-plus" variant="text" class="mb-5" @click="add.overlay = true;">Добавить сервис</v-btn>
  <v-text-field v-model="search" label="Поиск"></v-text-field>
  <v-data-table
    :headers="headers"
    :items="items"
    :loading="loading"
    :search="search"
    loading-text="Загружаем данные..."
  >
    <template v-slot:item.price="{ item }">
      <p>{{ item.price }} ₽</p>
    </template>
    <template v-slot:item.image="{ item }">
      <img :src="`${base_url}/${item.imagePath ? item.imagePath : ''}`" style="max-height: 50px;">
    </template>
    <template v-slot:item.actions="{ item }">
      <v-btn color="orange" variant="tonal" prepend-icon="mdi-pencil" class="ma-1" @click="open_edit_form(item.id)">Редактировать</v-btn>
      <v-btn color="red" variant="tonal" prepend-icon="mdi-delete" class="ma-1" @click="delete_service(item.id)">Удалить</v-btn>
    </template>
  </v-data-table>

  <v-overlay
    v-model="add.overlay"
    class="align-center justify-center"
  >
    <Card title="Добавить сервис" @closed="close_add_form();" @confirmed="add_service()">
      <v-text-field label="Name" v-model="add.data.fullName" maxlength="20"></v-text-field>
      <v-text-field label="Short Name" v-model="add.data.shortName" minlength="2" maxlength="3"></v-text-field>
      <v-text-field label="Regex Sender" v-model="add.data.senderRegexPattern"></v-text-field>
      <v-text-field label="Body Regex" v-model="add.data.bodyRegexPattern"></v-text-field>
      <v-text-field label="Price" v-model="add.data.price" prefix="₽" type="number"></v-text-field>
      <v-select label="Country" v-model="add.data.country" :items="countries"></v-select>

      <label class="mr-2">Image:</label>
      <input type="file" accept="image/*" ref="addNewServiceImageData"></input>
    </Card>
  </v-overlay>

  <v-overlay
    v-model="edit.overlay"
    class="align-center justify-center"
  >
    <Card title="Редактировать сервис" @closed="close_edit_form();" @confirmed="edit_service()">
      <v-text-field label="Name" v-model="edit.data.fullName" maxlength="20"></v-text-field>
      <v-text-field label="Short Name" v-model="edit.data.shortName" minlength="2" maxlength="3"></v-text-field>
      <v-text-field label="Regex Sender" v-model="edit.data.senderRegexPattern"></v-text-field>
      <v-text-field label="Body Regex" v-model="edit.data.bodyRegexPattern"></v-text-field>
      <v-text-field label="Price" v-model="edit.data.price" prefix="₽" type="number" min="0.01"></v-text-field>
      <v-select label="Country" v-model="edit.data.country" :items="countries"></v-select>

      <label class="mr-2">Image:</label>
      <input type="file" accept="image/*" ref="editServiceImageData"></input>
    </Card>
  </v-overlay>


  <v-overlay
    v-model="circle_loading"
    class="align-center justify-center"
    persistent
  >
    <v-progress-circular
      :size="50"
      color="primary"
      indeterminate
    ></v-progress-circular>
  </v-overlay>
</template>

<script>
import * as fun from '@/functions.js'
import Card from '@/components/Card.vue'

export default {
  name: 'AdminServices',
  components: {
    Card,
  },
  data() {
    return {
      itemsPerPage: 10,
      headers: [
        { title: 'ID', key: 'id' },
        { title: 'Name', key: 'fullName' },
        { title: 'ShortName', key: 'shortName' },
        { title: 'Price', key: 'price' },
        { title: 'Regex Sender', key: 'senderRegexPattern' },
        { title: 'Body Regex', key: 'bodyRegexPattern' },
        { title: 'Country', key: 'country' },
        { title: 'Image', key: 'image' },
        { title: 'Действия', key: 'actions' },
      ],
      search: '',
      items: [],
      loading: true,
      circle_loading: false,

      base_url: fun.BASE_URL,
      add: {
        overlay: false,
        data: {},
      },
      edit: {
        overlay: false,
        data: {},
      },
      countries: [],
    }
  },
  methods: {
    loadCountries(){
      this.axios({
        method: 'get',
        url: `${fun.SERVER_URL}/Countries`,
      }).then((response) => {
        console.log(response);
        this.countries = response.data.map((country) => country.shortName);
      }).catch((error) => {
        fun.show(this, "Произошла ошибка при загрузке стран")
      });
    },
    loadItems(){
      this.loading = true;
      this.axios({
        method: 'get',
        url: `${fun.SERVER_URL}/Services`,
      }).then((response) => {
        this.items = response.data.map((item) => {
          item.country = item.countryShortName;
          return item;
        });
        this.loading = false;
      }).catch((error) => {
        fun.show(this, "Произошла ошибка при загрузке данных")
        this.loading = false;
      });
    },
    delete_service(id){
      if(confirm("Are you sure you want to delete service - ID " + id + "?")){
        this.loading = true;
        this.axios({
          method: 'delete',
          url: `${fun.SERVER_URL}/Services/${id}`,
        }).then((response) => {
          for(let i = 0; i < this.items.length; i++) {
            if(this.items[i].id == id) {
              this.items.splice(i, 1);
            }
          }
          this.loading = false;
        }).catch((error) => {
          fun.show(this, "Произошла ошибка")
          this.loading = false;
        });
      }
    },
    add_service(){
      this.circle_loading = true;

      const file = this.$refs.addNewServiceImageData.files[0];

      // Создание объекта FormData и добавление файла
      const formData = new FormData();
      formData.append('file', file);
      this.axios.post(`${fun.SERVER_URL}/Uploads`, formData, {
        headers: {
          'Content-Type':'multipart/form-data'
        }
      })
      .then((res) => {
        
        let data = this.add.data;
        data.imageID = res.data.id;
        data.price = Number(data.price);
        console.log(data);
        this.axios({
          method: 'post',
          url: `${fun.SERVER_URL}/Services`,
          data: data,
        }).then((response) => {
          let final_obj = this.add.data;
          final_obj.id = Number(response.data);
          final_obj.image = res.data.url;

          this.items.push(final_obj);
          this.add.overlay = false;
          this.add.data = {};
          this.circle_loading = false;
        }).catch((error) => {
          fun.show(this, "Ошибка при отправке формы")
          this.circle_loading = false;
        });


      }).catch((error) => {
        fun.show(this, "Ошибка при загрузке файла")
        this.circle_loading = false;
      });

      
    },
    edit_service(){
      this.circle_loading = true;

      const file = this.$refs.editServiceImageData.files[0];

      // Создание объекта FormData и добавление файла
      const formData = new FormData();
      formData.append('file', file);
      this.axios.post(`${fun.SERVER_URL}/Uploads`, formData, {
        headers: {
          'Content-Type':'multipart/form-data'
        }
      })
      .then((res) => {
        
        let data = this.edit.data;
        data.imageID = res.data.id;
        data.price = Number(data.price);
        this.axios({
          method: 'put',
          url: `${fun.SERVER_URL}/Services/${this.edit.data.id}`,
          data: data,
        }).then((response) => {
          let final_obj = this.edit.data;
          final_obj.image = res.data.url;

          for(let i = 0; i < this.items.length; i++){
            if(this.items[i].id == this.edit.data.id){
              this.items[i] = final_obj;
              break;
            }
          }
          this.edit.overlay = false;
          this.edit.data = {};
          this.circle_loading = false;
        }).catch((error) => {
          console.log(error);
          fun.show(this, "Ошибка при отправке формы")
          this.circle_loading = false;
        });


      }).catch((error) => {
        fun.show(this, "Ошибка при загрузке файла")
        this.circle_loading = false;
      });

      
    },

    close_add_form(){
      this.add.data = {};
      this.add.overlay = false;
    },
    open_edit_form(id){
      for(let i = 0; i < this.items.length; i++){
        if(this.items[i].id == id){
          this.edit.data = {};
          const keys = Object.keys(this.items[i]);
          for(let j = 0; j < keys.length; j++){
            this.edit.data[keys[j]] = this.items[i][keys[j]];
          }
          this.edit.overlay = true;
          break;
        }
      }
    },
    close_edit_form(){
      this.edit.data = {};
      this.edit.overlay = false;
    },
  },
  mounted(){
    this.loadItems();
    this.loadCountries();
  },
}
</script>