<template>
  <img style="position: absolute; right: 0; margin-top: 60px; height: 250px;" :src="require('@/assets/bg-top_right.png')">
  <v-app class="bg-background-image">
    <div style="z-index: 10;">
      <Toolbar :login_successfull="login_successfull" :userObject="userObject" @update_user="get_user"></Toolbar>
      
      <ContentWrapper style="z-index: 10;" @update_user="get_user">
        <router-view :userObject="userObject" @fetch_user_balance="get_user_balance" @update_balance="update_balance"></router-view>
      </ContentWrapper>
    </div>
    

    <v-container class="d-flex flex-row justify-start" style="z-index: 0;">
      <img style="position: fixed; left: 0; margin-top: -500px; height: 500px;" :src="require('@/assets/bg-bottom_left.png')">
    </v-container>
    <Footer></Footer>
  </v-app>
  <notifications position="top right" />
</template>

<script>
import Toolbar from '@/components/Toolbar.vue'
import Footer from '@/components/Footer.vue'
import ContentWrapper from '@/components/ContentWrapper.vue'
import * as fun from '@/functions.js'

export default {
  name: 'App',
  components: {
    Toolbar,
    ContentWrapper,
    Footer,
  },
  data() {
    return {
      login_successfull: false,
      userObject: {},
    }
  },
  methods: {
    update_balance(payload){
      this.userObject.balance = payload.balance;
    },
    get_user(){
      return this.axios({
        method: 'get',
        url: `${fun.SERVER_URL}/Users/info`,
      }).then((response) => {
        this.userObject = response.data;
        this.$cookies.set('user', JSON.stringify(response.data));
      }).catch((error) => {
        console.log(error);
      });
    },
    get_user_balance(){
      return this.axios({
        method: 'get',
        url: `${fun.SERVER_URL}/Users/balance`,
      }).then((response) => {
        this.userObject.balance = response.data;
      }).catch((error) => {
        console.log(error);
      });
    },
  },
  mounted(){
    fun.refresh(this);
    fun.check_auth(this).then(response => {
      this.login_successfull = response;

      if(this.login_successfull){
        this.get_user().then(() => {
          setTimeout(this.get_user, 1000 * 60);
        });
        this.get_user_balance().then(() => {
          setTimeout(this.get_user_balance, 1000 * 60);
        });
      }
    })
  },
}
</script>

<style>
.message-error{
  position: fixed;
  top: 10vh;
  right: -200px;
  background-color: #ff2d1f;
  color: white;
  padding: 10px;
  transition: transform 0.5s, opacity 0.5s;
}
.message-success{
  position: fixed;
  top: 10vh;
  right: -200px;
  background-color: green;
  color: white;
  padding: 10px;
  transition: transform 0.5s, opacity 0.5s;
}

.bg-background-image{
  /* background-image: linear-gradient(rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.5)), url('./assets/orange-background.webp');
  background-repeat: repeat;
  background-size: cover; */
  background-color: white;
/* 
  background-position: center;
  background-attachment: fixed; */
}

::-moz-selection {
  color: white;
  background: #825bf3;
}
::selection {
  color: white;
  background: #825bf3;
}

</style>