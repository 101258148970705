<template>
  <v-container v-if="created_agent" fluid class="d-flex flex-column align-center rounded-lg mb-5" style="background: rgba(242, 92, 43, 0.3); border: 2px rgb(242, 92, 43) solid;">
    <div class="d-flex flex-row align-end">
      <p>Пароль созданного агента {{ created_agent.email }} - <b>{{ created_agent.password }}</b></p>
      <v-btn icon="mdi-content-copy" variant="text" title="Скопировать" density="compact" class="ml-1" @click="fun.copy_text(String(created_agent.password))"></v-btn>
    </div>

    <p>Обязательно сохраните этот пароль в надежное место, после он будет недоступен!</p>
  </v-container>

  <v-row class="d-flex flex-row justify-center">
    <v-col :cols="smAndDown ? 12 : 7" class="mr-2">
      <v-data-table
        :headers="headers"
        :items="agents_reversed"
        :search="search"
        :loading="loading.get_agents"
        loading-text="Загружаем..."
      >

      <template v-slot:item.description="{ item }">
        <v-text-field
        v-if="item.edit_mode"
        :rules="rules.description"
        label="Описание"
        density="compact"
        v-model="edit_agent_forms[String(item.id)].description"
        style="width: 250px;"
        class="mb-n5"
        ></v-text-field>

        <p v-else style="min-width: 100px;">{{ item.description || 'Нет описания' }}</p>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-container v-if="item.edit_mode" class="d-flex flex-row pa-0 ma-0">
          <v-btn icon="mdi-check" color="green" density="compact" variant="text" title="Подтвердить" @click="commit_changes(item.id)"></v-btn>
          <v-btn icon="mdi-close" color="red" density="compact" variant="text" title="Отменить" @click="discard_changes(item.id)"></v-btn>
        </v-container>
        <v-container v-else class="d-flex flex-row pa-0 ma-0">
          <v-btn icon="mdi-pencil" color="orange" density="compact" variant="text" title="Редактировать" @click="edit_agent(item.id)"></v-btn>
          <v-btn icon="mdi-delete" color="red" density="compact" variant="text" title="Удалить" @click="delete_agent(item.id)"></v-btn>
        </v-container>
      </template>

      </v-data-table>
    </v-col>

    <v-col :cols="smAndDown ? 12 : 4" class="ml-2">
      <v-form @submit.prevent="add_agent();">
        <v-card>
          <v-card-title>Добавить агента (устройство)</v-card-title>
          <v-card-text>
            <v-text-field label="Логин" v-model="create_agent.login" :rules="rules.login"></v-text-field>
            <v-text-field label="Краткое описание" v-model="create_agent.description" :rules="rules.description"></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn
            color="primary"
            type="submit"
            :disabled="create_agent_btn_disabled"
            :loading="loading.create_agent">Создать</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-col>
  </v-row>
</template>

<script setup>
import { useDisplay } from 'vuetify'

// Destructure only the keys you want to use
const { xs, sm, smAndDown, smAndUp, md, mdAndDown, mdAndUp, lg, lgAndDown, lgAndUp, xl, xlAndDown, xlAndUp, xxl } = useDisplay();
</script>

<script>
import * as fun from "@/functions.js";

export default {
  name: "PartnerMain",
  components: {},
  data() {
    return {
      headers: [
        {
          title: "ID",
          value: "id",
          key: "id",
          filterable: true,
        },
        {
          title: "Email",
          value: "email",
        },
        {
          title: "API ключ",
          value: "apiKey",
        },
        {
          title: "Описание",
          value: "description",
        },
        {
          title: "Действия",
          value: "actions",
        },
      ],
      agents: [],
      search: "",

      loading: {
        get_agents: false,
        create_agent: false,
      },
      create_agent: {
        login: "",
        description: "",
      },
      edit_agent_forms: {},
      rules: {
        login: [
          value => !!value || 'Поле обязательно',
          value => /^[\da-zA-Z]{5,20}$/.test(value) || 'От 5 до 20 символов, латинские буквы и цифры'
        ],
        description: [
          value => !!value || 'Поле обязательно',
          value => value.length <= 255 || 'Длина должна превышать 255 символов'
        ],
      },
      created_agent: null,
    };
  },
  methods: {
    loadAgents() {
      this.loading.get_agents = true;
      this.axios({
        method: "get",
        url: `${fun.SERVER_URL}/Agents`,
      })
        .then((response) => {
          this.agents = response.data;
        })
        .catch((error) => {
          fun.show(this, "Произошла ошибка при загрузке");
        })
        .finally(() => {
          this.loading.get_agents = false;
        });
    },
    add_agent(){
      this.loading.create_agent = true;
      this.axios({
        method: "post",
        url: `${fun.SERVER_URL}/Agents`,
        data: {
          login: this.create_agent.login,
          description: this.create_agent.description,
        },
      })
       .then((response) => {
          this.created_agent = response.data;
          this.created_agent.description = this.create_agent.description;
          fun.show(this, "Агент успешно создан", true);
          this.create_agent = {
            login: "",
            description: "",
          };
          this.agents.push(this.created_agent);
        })
       .catch((error) => {
          console.log(error);
          fun.show(this, "Введены некорректные данные");
        })
       .finally(() => {
          this.loading.create_agent = false;
        });
    },
    delete_agent(id){
      const email = this.agents.find(agent => agent.id == id).email;
      if(confirm(`Вы уверены, что хотите удалить агента ${email}?`)){
        this.loading.get_agents = true;
        this.axios({
          method: "delete",
          url: `${fun.SERVER_URL}/Agents/${id}`,
        })
         .then((response) => {
            fun.show(this, "Агент успешно удален", true);
            this.agents = this.agents.filter((agent) => {
              return agent.id != id;
            });
          })
         .catch((error) => {
            console.log(error);
            fun.show(this, "Произошла ошибка при удалении");
          })
         .finally(() => {
            this.loading.get_agents = false;
          });
      }
    },
    edit_agent(id){
      const agent = this.agents.find(agent => agent.id == id);
      this.edit_agent_forms[String(id)] = {
        description: String(agent.description),
      };
      agent.edit_mode = true;
    },
    commit_changes(id){
      const agent = this.agents.find(agent => agent.id == id);
      const new_description = this.edit_agent_forms[String(id)].description;

      this.axios({
        method: "put",
        url: `${fun.SERVER_URL}/Agents/${id}`,
        data: {
          agentId: id,
          description: new_description,
        },
      }).then((response) => {
        agent.description = new_description;
        agent.edit_mode = false;
      }).catch((error) => {
        console.log(error);
        fun.show(this, "Ошибка при изменении агента");
      });
    },
    discard_changes(id){
      const agent = this.agents.find(agent => agent.id == id);
      agent.edit_mode = false;
      delete this.edit_agent_forms[String(id)];
    },
  },
  computed: {
    create_agent_btn_disabled(){
      const login = this.create_agent.login;
      const description = this.create_agent.description;
      
      const login_validation = this.rules.login.every( func => func(login) === true );
      const description_validation = this.rules.description.every( func => func(description) === true );
      return !(login_validation && description_validation);
    },
    agents_reversed(){
      return this.agents.map((agent) => {
        agent.actions = "";
        agent.edit_mode = false;
        return agent;
      }).reverse();
    }
  },
  mounted() {
    this.loadAgents();
  },
};
</script>
