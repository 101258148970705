<template>
  <v-container class="mt-0 pt-0">
    <p class="text-center text-body-2">
      <span class="text-body-1">Информация</span>
      <br>
По умолчанию все ваши номера доступны для продажи для всех сервисов и не требуют дополнительной настройки.<br>
Если вы хотите ограничить продажу ваших номеров для определенных сервисов, добавьте нужный сервис в список с необходимым статусом. Доступны два статуса:<br>
● "Никому" - номера будут полностью исключены из продажи.<br>
● "Только мне" - номера будут продаваться только вам и никому другому.
    </p>
  </v-container>

  <v-row class="d-flex flex-row justify-center">

    <v-col :cols="smAndDown ? 12 : 6" class="mr-2">
      <p class="text-h6 mb-2">Сервисы, исключенные из продажи</p>

      <v-data-table
      :headers="headers"
      :items="disabled_services"
      :loading="loading.get_disabled_services"
      :items-per-page="5"
      >

      <template v-slot:item.price="{ item }">
        <p>{{ item.price }} ₽</p>
      </template>
      <template v-slot:item.serviceImage="{ item }">
        <img :src="`${fun.BASE_URL}/${item.serviceImage ? item.serviceImage : ''}`" style="height: 35px;">
      </template>
      <template v-slot:item.disableServiceStatus="{ item, index }">
        <v-switch
          v-model="item.disableServiceStatus"
          :false-value="2"
          :true-value="3"
          :label="`Продажа: ${item.disableServiceStatus == 2 ? 'никому' : 'только себе'}`"
          color="primary"
          :loading="item.updateInProgress"
          :disabled="item.updateInProgress"
          @update:modelValue="updateDisabledService(index)"
        ></v-switch>
        <v-btn
        color="green" variant="outlined" density="comfortable" class="mt-n12"
        :disabled="item.includeInProgress"
        :loading="item.includeInProgress"
        @click="includeService(index)">Вернуть в продажу</v-btn>
      </template>

      </v-data-table>
    </v-col>

    <v-col v-if="mdAndUp" cols="1">
      <v-divider vertical></v-divider>
    </v-col>
    
    <v-col :cols="smAndDown ? 12 : 5" :class="`${mdAndUp ? 'ml-n16' : ''}`">
      <p class="text-h6 mb-2">Добавить запрещённый сервис</p>

      <v-autocomplete
        v-model="selected_service_to_disable.shortName"
        :items="services"
        item-value="shortName"
        item-title="fullName"
        fluid
        label="Выберите сервис"
        :loading="loading.get_all_services"
      >
      </v-autocomplete>
      <v-autocomplete
        :disabled="!selected_service_to_disable.shortName"
        v-model="selected_service_to_disable.countryShortName"
        :items="countries"
        item-value="countryShortName"
        item-title="countryFullName"
        fluid
        label="Выберите страну"
        :loading="loading.get_all_services"
      >
      </v-autocomplete>

      <v-row>

        <v-col :cols="mdAndDown ? 12 : 6" :class="`d-flex flex-row justify-center px-${mdAndDown ? 2 : 0}`">
          <v-btn
          color="primary"
          flat
          :block="mdAndDown"
          :disabled="!(selected_service_to_disable.countryShortName && selected_service_to_disable.shortName) || loading.service_disabling"
          @click="disableSelectedService(2)"
          >
            Исключить из продажи
          </v-btn>
        </v-col>
        <v-col :cols="mdAndDown ? 12 : 6"
        :class="`d-flex flex-row justify-center px-${mdAndDown ? 2 : 0} ${mdAndDown ? 'mt-n4' : ''}`">
          <v-btn
          color="purple-darken-1"
          flat
          :block="mdAndDown"
          :disabled="!(selected_service_to_disable.countryShortName && selected_service_to_disable.shortName) || loading.service_disabling"
          @click="disableSelectedService(3)"
          >
            Продажа только себе
          </v-btn>
        </v-col>

      </v-row>

    </v-col>
  </v-row>
</template>

<script setup>
import { useDisplay } from "vuetify";

// Destructure only the keys you want to use
const {
  xs,
  sm,
  smAndDown,
  smAndUp,
  md,
  mdAndDown,
  mdAndUp,
  lg,
  lgAndDown,
  lgAndUp,
  xl,
  xlAndDown,
  xlAndUp,
  xxl,
} = useDisplay();
</script>

<script>
import * as fun from "@/functions.js";

export default {
  name: "PartnerServices",
  data() {
    return {
      headers: [
        {
          value: "serviceImage",
        },
        {
          title: "Сервис",
          value: "name",
        },
        {
          title: "Страна",
          value: "countryName",
        },
        {
          title: "Цена",
          value: "price",
        },
        {
          title: "Статус",
          value: "disableServiceStatus",
        },
      ],
      all_services: [],
      disabled_services: [],

      selected_service_to_disable: {
        countryShortName: null,
        shortName: null,
      },
      loading: {
        get_all_services: false,
        get_disabled_services: false,
        service_disabling: false,
      },
    };
  },
  methods: {
    loadAllServices() {
      this.loading.get_all_services = true;
      this.axios({
        method: "get",
        url: `${fun.SERVER_URL}/Services`,
      })
        .then((response) => {
          this.all_services = response.data;
        })
        .catch((error) => {
          fun.show(this, "Произошла ошибка при загрузке");
        })
        .finally(() => {
          this.loading.get_all_services = false;
        });
    },
    loadDisabledServices() {
      this.loading.get_disabled_services = true;
      this.axios({
        method: "get",
        url: `${fun.SERVER_URL}/Partners/disableServices`,
      })
        .then((response) => {
          this.disabled_services = response.data.disableServices.reverse();
        })
        .catch((error) => {
          fun.show(this, "Произошла ошибка при загрузке");
        })
        .finally(() => {
          this.loading.get_disabled_services = false;
        });
    },

    updateDisabledService(index){
      this.disabled_services[index].updateInProgress = true;

      this.axios({
        method: "put",
        url: `${fun.SERVER_URL}/Partners/disableService`,
        data: {
          id: this.disabled_services[index].id,
          disableServiceStatus: this.disabled_services[index].disableServiceStatus,
        },
      }).then((response) => {
        // do nothing
      }).catch((error) => {
        console.error(error);
        fun.show(this, "Произошла ошибка");
        if(this.disabled_services[index].disableServiceStatus == 2) this.disabled_services[index].disableServiceStatus = 3;
        else this.disabled_services[index].disableServiceStatus = 2;
      }).finally(() => {
        this.disabled_services[index].updateInProgress = false;
      });
    },
    includeService(index){
      this.disabled_services[index].includeInProgress = true;
      
      this.axios({
        method: "post",
        url: `${fun.SERVER_URL}/Partners/includeService`,
        data: {
          servicesShortName: this.disabled_services[index].shortName,
          countryShortName: this.disabled_services[index].countryShortName,
        },
      }).then((response) => {
        this.disabled_services[index].includeInProgress = false;
        this.disabled_services = this.disabled_services.filter((obj, ind) => index != ind);
      }).catch((error) => {
        console.error(error);
        fun.show(this, "Произошла ошибка");
        this.disabled_services[index].includeInProgress = false;
      })
    },

    disableSelectedService(status){
      this.loading.service_disabling = true;

      this.axios({
        method: "post",
        url: `${fun.SERVER_URL}/Partners/disableServices`,
        data: {
          servicesShortName: this.selected_service_to_disable.shortName,
          countryShortName: this.selected_service_to_disable.countryShortName,
          disableServiceStatus: status
        },
      }).then((response) => {
        fun.show(this, "Исключение добавлено успешно", true);
        this.selected_service_to_disable = {
          countryShortName: null,
          shortName: null,
        };
        this.disabled_services = [response.data, ...this.disabled_services];
      }).catch((error) => {
        console.error(error);
        fun.show(this, "Произошла ошибка");
      }).finally(() => {
        this.loading.service_disabling = false;
      });
    },
  },
  computed: {
    active_services() {
      return this.all_services.filter((obj) => {
        return !( this.disabled_services.some((ser) => 
        ser.shortName == obj.shortName && ser.countryShortName == obj.countryShortName
        ) );
      });
      // return this.all_services;
    },
    services() {
      return this.active_services.map((obj) => {
        return {
          shortName: obj.shortName,
          fullName: obj.fullName,
        }
      });
    },
    countries() {
      return this.active_services.filter((obj) => obj.shortName == this.selected_service_to_disable.shortName).map((obj) => {
        return {
          countryFullName: obj.countryFullName,
          countryShortName: obj.countryShortName,
        }
      });
    },
  },
  mounted() {
    this.loadAllServices();
    this.loadDisabledServices();
  },
};
</script>
