<template>
  <v-container :class="`${xs ? 'pa-4' : ''}`">
    <v-row class="d-flex flex-row align-center">
      <v-col :cols="smAndDown ? 7 : 9">
        <div class="w-100">
          <p class="mb-1">Сортировать по дате: </p>
          <VueDatePicker v-model="date_range" range @update:model-value="handleDate"></VueDatePicker>
        </div>
      </v-col>
      <v-col :cols="smAndDown ? 5 : 3">
        <v-switch
        v-model="active_orders"
        :label="active_orders ? 'Активые заказы' : 'Все заказы'"
        inset
        color="primary"
        class="mb-n12"
        @update:model-value="loadOrders"
        ></v-switch>
      </v-col>
    </v-row>
  </v-container>

  <v-data-table-server
    v-model:items-per-page="itemsPerPage"
    v-model:page="page"
    :headers="headers"
    :items="serverItems"
    :items-length="totalItems"
    :loading="loading"
    loading-text="Загружаем данные..."
    @update:options="loadOrders"
    :items-per-page-options="[]"
  >

    <template v-slot:item.serviceName="{ item }">
      <div class="d-flex flex-row align-center">
        <img :src="`${fun.BASE_URL}/${item.serviceImagePath ? item.serviceImagePath : 'uploads/images/no-image.svg'}`" style="max-height: 30px;">
        <p class="ml-2"><b>{{ item.serviceName }}</b></p>
      </div>
    </template>
    <template v-slot:item.countryShortName="{ item }">
        <img :src="`${fun.BASE_URL}/${item.countryImagePath ? item.countryImagePath : 'uploads/images/no-image.svg'}`" style="max-height: 30px;">
    </template>
    <template v-slot:item.created="{ item }">
      <p>{{ formatDateWithTimezone(item.created) }}</p>
    </template>
    <template v-slot:item.phoneNumber="{ item }">
      <div class="d-flex flex-row align-center">
        <p>{{ '+' + item.phoneNumber }}</p>
        <v-btn icon="mdi-content-copy" variant="text" size="small" density="compact" title="Скопировать" class="ml-1" @click="fun.copy_text(String(item.phoneNumber))"></v-btn>
      </div>
    </template>

    <template v-slot:item.activationStatus="{ item }">
      <div v-if="item.codes.length > 0">
        <p>Код:</p>
        <div class="d-flex flex-row align-center">
          <p class="font-weight-bold">{{ item.codes[item.codes.length - 1] }}</p>
          <v-btn icon="mdi-content-copy" variant="text" size="small" density="compact" title="Скопировать" class="ml-1" @click="fun.copy_text(String(item.codes[item.codes.length - 1]))"></v-btn>
        </div>
        <p v-if="item.codes.length > 1" @click="item.codes_tooltip_show = !Boolean(item.codes_tooltip_show)" class="opacity-80 cursor-pointer">Просмотреть все коды</p>
        <v-overlay
        v-model="item.codes_tooltip_show"
        class="align-center justify-center"
        >
          <v-card>
            <v-card-title class="headline font-weight-bold">Коды для {{ '+' + item.phoneNumber }}</v-card-title>
            <v-card-text>
                <div v-for="(code, index) in item.codes" :key="code + index" class="d-flex flex-row align-center">
                  <p class="mr-2">{{ item.codes.length - index }}-й</p>
                  <p class="text-body-1 font-weight-bold">{{ item.codes[item.codes.length - 1 - index] }}</p>
                  <v-btn icon="mdi-content-copy" variant="text" size="small" density="compact" title="Скопировать" class="ml-1" @click="fun.copy_text(String(item.codes[item.codes.length - 1 - index]))"></v-btn>
                </div>
            </v-card-text>
            <v-card-actions class="mt-n4">
              <v-btn color="primary" block @click="item.codes_tooltip_show = false">Готово</v-btn>
            </v-card-actions>
          </v-card>
        </v-overlay>
      </div>
      <div v-if="item.activationStatus != 6" class="status_chips my-2">
        <v-chip
          v-if="item.activationStatus == 1"
          color="orange"
          :text="`Ожидает SMS ${item.timer || ''}`"
          class="text-uppercase"
          size="default"
          label
        ></v-chip>
        <v-chip
          v-else-if="item.activationStatus == 3"
          color="orange"
          :text="`Повтор запроса ${item.timer || ''}`"
          class="text-uppercase"
          size="default"
          label
        ></v-chip>
        <v-chip
          v-else-if="item.activationStatus == 6"
          color="green"
          text="Успешно"
          class="text-uppercase"
          size="default"
          label
        ></v-chip>
        <v-chip
          v-else-if="item.activationStatus == -1"
          color="red"
          text="Отмена"
          class="text-uppercase"
          size="default"
          label
        ></v-chip>
        <v-chip
          v-else-if="item.activationStatus == 8"
          color="red"
          text="Заблокирован"
          class="text-uppercase"
          size="default"
          label
        ></v-chip>
      </div>
      <div class="action_buttons mt-1 mb-2">
        <div class="d-flex flex-column" v-if="item.activationStatus == 1">
          <v-btn
          @click="updateOrderStatus(item, -1)"
          color="orange"
          size="small"
          variant="tonal"
          :disabled="new Date() - new Date(item.created) < 1000 * 60"
          >
            Отменить {{ new Date() - new Date(item.created) < 1000 * 60 ? item.timerToCancellation : '' }}
          </v-btn>

          <v-btn
          @click="updateOrderStatus(item, 8)"
          class="mt-1"
          color="red"
          size="small"
          variant="tonal"
          prepend-icon="mdi-alert"
          :disabled="new Date() - new Date(item.created) < 1000 * 60 * 3"
          >
            Номер заблокирован {{ new Date() - new Date(item.created) < 1000 * 60 * 3 ? item.timerToBlockUnlock : '' }}
          </v-btn>
        </div>
        <div v-else-if="item.activationStatus == 6">
          <v-btn
          v-if="(new Date() - new Date(item.created)) < 1000 * 60 * 60 * 4"
          @click="updateOrderStatus(item, 3)"
          color="greeny"
          size="small"
          variant="flat"
          >
            <template v-slot:prepend>
              <v-icon color="white">mdi-refresh</v-icon>
            </template>
            <p class="text-white">Получить заново</p>
          </v-btn>
        </div>
      </div>
    </template>

    <template v-slot:item.price="{ item }">
      <p>{{ item.price }} ₽</p>
    </template>
  </v-data-table-server>
</template>

<script setup>
import { useDisplay } from "vuetify";

// Destructure only the keys you want to use
const {
  xs,
  sm,
  smAndDown,
  smAndUp,
  md,
  mdAndDown,
  mdAndUp,
  lg,
  lgAndDown,
  lgAndUp,
  xl,
  xlAndDown,
  xlAndUp,
  xxl,
} = useDisplay();
</script>

<script>
import * as fun from '@/functions.js'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import * as signalR from '@microsoft/signalr';

export default {
  name: 'PartnerHistory',
  components: {
    VueDatePicker,
  },
  data() {
    return {
      itemsPerPage: 10,
      page: 1,
      headers: [
        { title: 'ID', value: 'id' },
        { title: 'Сервис', value: 'serviceName' },
        { title: 'Страна', value: 'countryShortName' },
        { title: 'Номер', value: 'phoneNumber' },
        { title: 'Статус', value: 'activationStatus' },
        { title: 'Цена', value: 'price' },
        { title: 'Дата', value: 'created' },
      ],
      serverItems: [],
      loading: false,
      totalItems: 0,
      date_range: [],
      active_orders: false,
      waiting_for_sms_status_codes: [1, 3],
      hubConnection: null,
      wsReconnectAttempts: 0,
      wsMaxReconnectAttempts: 5
    }
  },
  methods: {
    loadOrders(){
      const dates_obj = { createdFrom: this.date_range[0] ? fun.convertToGMTString(this.date_range[0]) : null, createdTo: this.date_range[1] ? fun.convertToGMTString(this.date_range[1]) : null };
      if(this.active_orders) this.loadItems({ page: this.page, itemsPerPage: this.itemsPerPage }, true, dates_obj);
      else this.loadItems({ page: this.page, itemsPerPage: this.itemsPerPage }, false, dates_obj);
    },
    loadItems({ page, itemsPerPage }, isActiveOrder, dates = {} ){
      this.loading = true;
      this.axios({
        method: 'get',
        url: `${fun.SERVER_URL}/Orders`,
        params: {
          pageNumber: page,
          pageSize: itemsPerPage,
          isActiveOrder: isActiveOrder,
          createdFrom: dates?.createdFrom || undefined,
          createdTo: dates?.createdTo || undefined,
        },
      }).then((response) => {
        this.serverItems = response.data.items;
        this.totalItems = response.data.totalCount;
        this.loading = false;
      }).catch((error) => {
        console.error(error);
        fun.show(this, "Произошла ошибка при загрузке данных")
        this.loading = false;
      });
    },
    formatDateWithTimezone(date){
      return fun.formatDateWithTimezone(date);
    },
    handleDate(date){
      if(date?.length == 2){
        if(this.active_orders){
          this.page = 1;
          this.loadItems({ page: 1, itemsPerPage: this.itemsPerPage }, true, { createdFrom: fun.convertToGMTString(date[0]), createdTo: fun.convertToGMTString(date[1]) })
        }
        else{
          console.log(fun.convertToGMTString(date[0]));
          this.page = 1;
          this.loadItems({ page: 1, itemsPerPage: this.itemsPerPage }, false, { createdFrom: fun.convertToGMTString(date[0]), createdTo: fun.convertToGMTString(date[1]) })
        }
      }
      else{
        this.page = 1;
        this.loadItems({ page: 1, itemsPerPage: this.itemsPerPage }, false);
      }
    },
    updateOrderStatus(order, status){
      this.axios({
        method: 'put',
        url: `${fun.SERVER_URL}/Orders/status`,
        params: {
          id: order.id,
          status: status,
        },
      }).then((response) => {
        order.activationStatus = status;
        this.$emit('fetch_user_balance');
      }).catch((error) => {
        console.error(error);
      });
    },
    updateOrderData(data){
      const parsed_data = JSON.parse(data);
      console.log("parsed data", parsed_data);
      for(let i = 0; i < this.serverItems.length; i++){
        const order = this.serverItems[i];
        if(order.id == parsed_data.Id) {
          order.codes = parsed_data.Codes;
          order.activationStatus = parsed_data.ActivationStatus;
          this.$emit('fetch_user_balance');
          return;
        }
      }
      this.loadOrders();
    },
    updateTimers() {
      for(let i = 0; i < this.serverItems.length; i++) {
        const item = this.serverItems[i];

        function getTimeLeft(timeInMs){
          const futureDate = new Date(timeInMs);
          const now = new Date();
          const difference = futureDate - now;

          if (difference <= 0) return "00:00";
          else{
            const minutes = Math.floor((difference / 1000 / 60) % 60);
            const seconds = Math.floor((difference / 1000) % 60);

            const formattedTime = `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
            return formattedTime;
          }
        }

        item.timer = getTimeLeft(item.timeToAutoClose);
        item.timerToCancellation = getTimeLeft(new Date(item.created).getTime() + 1000 * 60);
        item.timerToBlockUnlock = getTimeLeft(new Date(item.created).getTime() + 1000 * 60 * 3);
      }
    },
    startLiveConnection(){
      if (this.wsReconnectAttempts >= this.wsMaxReconnectAttempts) {
        fun.show(this, "Ошибка при подключении, попробуйте обновить страницу");
        return;
      }

      let hubConnection = new signalR.HubConnectionBuilder()
        .withUrl(`${fun.WEBSOCKET_URL}/orderswshub`, {
          skipNegotiation: true,
          accessTokenFactory: () => this.$cookies.get("token")?.accessToken || null,
          transport: signalR.HttpTransportType.WebSockets
        })
        .build();

      this.hubConnection = hubConnection;

      hubConnection.on("Send", (data) => {
        this.updateOrderData(data);
      });

      hubConnection.start().then(() => {
        console.log("Connected to the hub successfully.");
        this.wsReconnectAttempts = 0;
      }).catch((err) => {
        this.wsReconnectAttempts++;
        console.error(`Connection failed. Retrying (${this.wsReconnectAttempts}/${this.wsMaxReconnectAttempts})...`, err);
        setTimeout(() => {
          this.startLiveConnection();
        }, 3000);
      });
    },
  },
  mounted(){
    this.updateTimers();
    this.interval = setInterval(this.updateTimers, 1000);
    this.startLiveConnection();
  },
}
</script>

<style>
.dp__theme_light {
    --dp-background-color: #fff;
    --dp-text-color: #212121;
    --dp-hover-color: #f3f3f3;
    --dp-hover-text-color: #212121;
    --dp-hover-icon-color: #959595;
    --dp-primary-color: #f25c2b;
    --dp-primary-disabled-color: #dd7f5f;
    --dp-primary-text-color: #f8f5f5;
    --dp-secondary-color: #c0c4cc;
    --dp-border-color: #ddd;
    --dp-menu-border-color: #ddd;
    --dp-border-color-hover: #aaaeb7;
    --dp-border-color-focus: #aaaeb7;
    --dp-disabled-color: #f6f6f6;
    --dp-scroll-bar-background: #f3f3f3;
    --dp-scroll-bar-color: #959595;
    --dp-success-color: #76d275;
    --dp-success-color-disabled: #a3d9b1;
    --dp-icon-color: #959595;
    --dp-danger-color: #ff6f60;
    --dp-marker-color: #ff6f60;
    --dp-tooltip-color: #fafafa;
    --dp-disabled-color-text: #8e8e8e;
    --dp-highlight-color: rgb(25 118 210 / 10%);
    --dp-range-between-dates-background-color: var(--dp-hover-color, #f3f3f3);
    --dp-range-between-dates-text-color: var(--dp-hover-text-color, #212121);
    --dp-range-between-border-color: var(--dp-hover-color, #f3f3f3);
}

.codes_list {
  transform: rotate(180deg);
}
.codes_list > li {
  transform: rotate(-180deg);
}
</style>