<template>
    <v-container fluid class="d-flex flex-row justify-center">
        <v-form
        @submit.prevent="signin"
        :class="{'bg-surface rounded elevation-3': true, 'pa-6 pb-3': smAndUp, 'pa-4 pb-1': xs}" :style="`width: ${xs ? '100vw' : '450px'};`">
            <p class="text-h5 mb-4">Вход в аккаунт</p>
            <v-text-field v-model="email" label="Email" type="email" required :rules="[v => !!v || 'Email обязателен', v => /.+@.+\..+/.test(v) || 'Email должен быть валидным']"></v-text-field>
            <v-text-field v-model="password" label="Пароль" type="password" required></v-text-field>
            <v-btn type="submit" color="primary" block :disabled="is_btn_disabled || button_disabled" >Войти</v-btn>

            <v-container class="d-flex flex-column align-center" fluid>
                <p>Еще нет аккаунта? <router-link to="/auth/signup" style="color: #825bf3; text-decoration: none;">Зарегистрироваться</router-link></p>
                <v-container class="ma-0 pa-0 pl-9">
                    <p><router-link to="/auth/forgot-password" style="color: #825bf3; text-decoration: none;">Забыли пароль?</router-link></p>
                </v-container>
            </v-container>
        </v-form>
    </v-container>
</template>


<script setup>
import { useDisplay } from 'vuetify'

// Destructure only the keys you want to use
const { xs, sm, smAndDown, smAndUp, md, mdAndDown, mdAndUp, lg, lgAndDown, lgAndUp, xl, xlAndDown, xlAndUp, xxl } = useDisplay();
</script>

<script>
import * as fun from '@/functions.js'

export default {
    name: 'SignIn',
    data() {
        return {
            email: '',
            password: '',
            button_disabled: false,
        };
    },
    methods: {
        signin() {
            this.button_disabled = true;
            delete this.axios.defaults.headers['Authorization'];
            this.axios({
                method: 'post',
                url: `${fun.SERVER_URL}/Users/login`,
                data: {
                    email: this.email,
                    password: this.password,
                },
            }).then((response) => {
                this.button_disabled = false;

                let token_obj = response.data;
                token_obj.updatedAt = new Date().getTime();
                this.$cookies.set('token', JSON.stringify(token_obj));
                this.axios.defaults.headers['Authorization'] = `${token_obj.tokenType} ${token_obj.accessToken}`;

                this.axios({
                    method: 'get',
                    url: `${fun.SERVER_URL}/Users/info`,
                }).then((response) => {
                    this.userObject = response.data;
                    this.$cookies.set('user', JSON.stringify(response.data));

                    if(response.data.emailConfirmed){
                        fun.show(this, "Успешная авторизация", true);
                        setTimeout(() => {
                            window.location.href = `${fun.HOST}/`;
                        }, 500);
                    } else{
                        this.$router.push('/auth/confirm-email');
                    }
                }).catch((error) => {
                    console.log(error);
                });

            }).catch((error) => {
                this.button_disabled = false;
                console.log(error)
                fun.show(this, 'Введены некорректные данные');
            });
        }
    },
    computed: {
        is_btn_disabled(){
            return this.email == '' || this.password == '';
        }
    },
    mounted() {
        if(this.$route.query['update']){
            window.location.href = `${fun.HOST}${this.$route.path}`;
        }
    },
};
</script>