<template>
  <v-container fluid class="container">
    <p class="text-h5">Для разработчиков</p>
    <p class="text-body-1 mb-2">Если вы являетесь разработчиком программного обеспечения для регистрации и заполнения аккаунтов в различных системах, у нас есть для вас отличная возможность заработать на нашем сервисе. Присоединяйтесь к нашему сервису и получайте до 3% от каждого купленного SMS через ваш софт.</p>
    <p class="text-h6">Как это работает:</p>
    <ol>
      <li><span class="font-weight-medium">Заполните анкету:</span> Отправьте нам анкету через службу поддержки.</li>
      <li><span class="font-weight-medium">Рассмотрение:</span> Мы рассмотрим вашу анкету и свяжемся с вами для обсуждения дальнейших деталей сотрудничества.</li>
      <li><span class="font-weight-medium">Станьте партнёром:</span> После одобрения вы получите возможность зарегистрироваться как партнёр и выводить средства на крипто биржу Garantex.</li>
    </ol>
    <p class="text-h6">Анкета для разработчиков:</p>
    <ul>
      <li><span class="font-weight-medium">Название программы</span></li>
      <li><span class="font-weight-medium">Ссылка на программу</span></li>
      <li><span class="font-weight-medium">Краткое описание возможностей программы</span></li>
    </ul>
    <p class="text-h6">Преимущества для разработчиков:</p>
    <ul>
      <li><span class="font-weight-medium">Выплаты до 3%:</span> Получайте вознаграждение от каждого купленного SMS через ваш софт.</li>
      <li><span class="font-weight-medium">Регистрация как партнёр:</span> Доступ к удобной системе вывода средств на крипто биржу Garantex.</li>
      <li><span class="font-weight-medium">Реклама:</span> Вы получите дополнительный трафик с нашего сайта. Мы разместим вашу ссылку на продукт в специальной категории наших партнёров, люди, которые пользуются услугами в сфере SMS-активаций, увидят вас на нашем сайте!</li>
    </ul>
    <p class="text-body-1 mt-5">Присоединяйтесь к нашей программе для разработчиков и начните зарабатывать с нами. Мы ценим ваше сотрудничество и стремимся к взаимовыгодному партнёрству!</p>
  </v-container>
</template>

<script>
import * as fun from '@/functions.js'

export default {
  name: 'Referral',
  data() {
    return {
    }
  },
}
</script>

<style scoped>
.container *{
  margin-bottom: 5px;
}
.container *:last-child{
  margin-bottom: 0;
}
ul, ol{
  margin-left: 20px;
}
</style>
