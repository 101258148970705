<template>
  <v-container fluid class="container">
    <v-row>
      <v-col cols="12" md="5" style="max-width: 300px;">
        <p class="text-h6 font-weight-regular">Способы пополнения</p>

        <v-item-group mandatory v-model="selected_method_index">
          <v-container v-for="method in payment_methods" :key="method" fluid class="pa-2 pl-0">
            <v-item v-slot="{ isSelected, toggle }">
              <div
                class="d-flex flex-row justify-space-around align-center rounded-xl bg-background pa-5 py-3 cursor-pointer"
                :style="`border: #e8e8e8 solid 3px; ${isSelected ? 'border-color: #825bf3;' : ''}`"
                @click="toggle"
              >
                <img :src="method.img" class="payment_method_image">
                <p class="text-body-1 w-100 ml-5">{{ method.name }}</p>
              </div>
            </v-item>
          </v-container>
        </v-item-group>
      </v-col>
      <v-col v-if="mdAndUp" cols="1" class="d-flex flex-row justify-center">
        <v-divider vertical class="ml-n2"></v-divider>
      </v-col>
      <v-col cols="12" md="6">
        <p class="text-h6 font-weight-regular mb-2">Текущий баланс: {{ userObject.balance }} ₽</p>
        <v-form @submit.prevent="selected_method.name != 'Garantex' ? pay() : pay_garantex()" class="w-100">
          <v-card class="w-100" elevation="4">
            <v-card-title>Пополнение баланса</v-card-title>
            <v-card-text class="pb-0">
              <div v-if="selected_method.name != 'Garantex'">
                <label>Сумма пополнения</label>
                <v-text-field
                  v-model="amount"
                  type="number"
                  required
                  variant="outlined"
                  density="comfortable"
                  append-inner-icon="mdi-currency-rub"
                  :rules="selected_method.rules"
                />
              </div>
              <div v-else>
                <label>Код Garantex</label>
                <v-text-field
                  v-model="garantex.code"
                  required
                  variant="outlined"
                  density="comfortable"
                  :rules="selected_method.rules"
                />
              </div>
            </v-card-text>
            <v-card-subtitle v-if="selected_method.name != 'Garantex'">
              <p>Комиccия выбраного метода - {{ selected_method.comision * 100 }}%</p>
            </v-card-subtitle>
            <v-card-actions>
              <v-btn v-if="selected_method.name != 'Garantex'" block variant="flat" color="primary" type="submit" :disabled="pay_btn_disabled || payment_processing" :loading="payment_processing">
                Оплатить {{ Number(Number(amount) + Number(selected_method.comision * Number(amount))).toFixed(2) }} ₽
              </v-btn>
              <v-btn v-else block variant="flat" color="primary" type="submit" :disabled="garantex_btn_disabled || garantex.payment_processing" :loading="garantex.payment_processing">
                Отправить
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>

  <v-container fluid class="container">
    <div class="d-flex flex-row">
      <v-icon color="primary" class="mt-1 mr-1">mdi-arrow-up-bold-circle-outline</v-icon>
      <p class="text-h6 font-weight-regular mb-2">История пополнений</p>
    </div>

    <v-data-table-server
      v-model:items-per-page="itemsPerPage"
      v-model:page="page"
      :headers="headers"
      :items="serverItems"
      :items-length="totalItems"
      :loading="loading"
      loading-text="Загружаем данные..."
      @update:options="loadItems"
    >
    <template v-slot:item.amount="{ item }">
      <p class="font-weight-medium">{{ item.amount }} ₽</p>
    </template>
    <template v-slot:item.created="{ item }">
      <p>{{ fun.formatDateWithTimezone(item.created) }}</p>
    </template>
    <template v-slot:item.paymentStatus="{ item }">
      <v-chip
        v-if="item.paymentStatus == 0"
        color="orange"
        text="Обрабатывается"
        class="text-uppercase"
        size="small"
        label
      ></v-chip>
      <v-chip
        v-else-if="item.paymentStatus == 1"
        color="green"
        text="Успешно"
        class="text-uppercase"
        size="small"
        label
      ></v-chip>
      <v-chip
        v-else-if="item.paymentStatus == 2"
        color="red"
        text="Платеж не удался"
        class="text-uppercase"
        size="small"
        label
      ></v-chip>
      <v-chip
        v-else-if="item.paymentStatus == 3"
        color="red"
        text="Отменен"
        class="text-uppercase"
        size="small"
        label
      ></v-chip>
      <v-chip
        v-else-if="item.paymentStatus == 4"
        color="green"
        text="Возвращен"
        class="text-uppercase"
        size="small"
        label
      ></v-chip>
    </template>
    </v-data-table-server>
  </v-container>
</template>

<script setup>
import { useDisplay } from "vuetify";

// Destructure only the keys you want to use
const {
  xs,
  sm,
  smAndDown,
  smAndUp,
  md,
  mdAndDown,
  mdAndUp,
  lg,
  lgAndDown,
  lgAndUp,
  xl,
  xlAndDown,
  xlAndUp,
  xxl,
} = useDisplay();
</script>

<script>
import * as fun from '@/functions.js'

export default {
  name: 'Balance',
  props: {
    userObject: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      payment_methods: [
        {
          name: 'Банковская карта',
          img: require('@/assets/bank_card.png'),
          comision: 0.05,
          rules: [
            v => !isNaN(v) || 'Сумма должна быть числом',
            v => v >= 100 || 'Сумма не меньше 100 ₽',
            v => v <= 100000 || 'Сумма не больше 100 000 ₽',
          ],
        },
        {
          name: 'Garantex',
          img: require('@/assets/garantex.png'),
          comision: 0.015,
          rules: [
            v => !!v || 'Код обязателен',
            v => /^[A-Z0-9]+$/.test(v) || 'Только заглавные латинские буквы и цифры'
          ],
        },
      ],
      payment_processing: false,
      selected_method_index: 0,
      amount: 100,
      garantex: {
        code: '',
        payment_processing: false,
      },

      itemsPerPage: 10,
      page: 1,
      headers: [
        { title: 'Метод', value: 'paymentMethod' },
        { title: 'Сумма', value: 'amount' },
        { title: 'Описание', value: 'description' },
        { title: 'Статус', value: 'paymentStatus' },
        { title: 'Дата', value: 'created' },
      ],
      serverItems: [],
      loading: false,
      totalItems: 0,
    }
  },
  computed: {
    selected_method() {
      return this.payment_methods[this.selected_method_index]
    },
    pay_btn_disabled() {
      return !this.selected_method || !this.amount || !this.selected_method.rules.every((f) => f(this.amount) === true);
    },
    garantex_btn_disabled() {
      return !this.selected_method || !this.garantex.code || !this.selected_method.rules.every((f) => f(this.garantex.code) === true);
    },
  },
  methods: {
    loadItems({ page, itemsPerPage, sortBy }){
      this.loading = true;
      this.axios({
        method: 'get',
        url: `${fun.SERVER_URL}/Deposit/history`,
        params: {
          pageNumber: page,
          pageSize: itemsPerPage,
        },
      }).then((response) => {
        this.serverItems = response.data.items;
        this.totalItems = response.data.totalCount;
        this.loading = false;
      }).catch((error) => {
        console.log(error);
        fun.show(this, "Произошла ошибка при загрузке данных");
        this.loading = false;
      });
    },
    pay(){
      this.payment_processing = true;
      setTimeout(() => {
        this.payment_processing = false;
      }, 2000);
    },
    pay_garantex(){
      this.garantex.payment_processing = true;
      this.axios({
        method: 'post',
        url: `${fun.SERVER_URL}/Deposit/garantex`,
        data: {
          code: this.garantex.code,
        },
      }).then((response) => {
        this.garantex.code = '';
        this.loadItems({ page: this.page, itemsPerPage: this.itemsPerPage });
        this.$emit('update_balance', { balance: response.data.userBalance });
        fun.show(this, `Баланс пополнен на ${response.data.amount} ₽`, true);
      }).catch((error) => {
        console.log(error);
        fun.show(this, "Введен некорректный код или произошла ошибка");
      }).finally(() => {
        this.garantex.payment_processing = false;
      });
    },
  },
}
</script>

<style scoped>
.payment_method_image{
  width: 50px;
  height: auto;
  border-radius: 20%;
  color: #e8e8e8;
}
</style>
