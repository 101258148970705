<template>
    <v-card style="width: 100%; max-width: 450px; height: 100%;" elevation="3" class="rounded-lg pt-1">
        <v-card-title>Сервисы для активации</v-card-title>
        <v-card-text>
            <v-row class="mt-0 px-4">
                <img :src="`${fun.BASE_URL}/${countries?.filter((val) => val.country == selected_country)[0]?.imageUrl || 'uploads/images/no-image.svg'}`"
                    style="width: 52px; height: 39px; border-radius: 5px;" class="mt-2 mr-3">
                <v-autocomplete
                label="Cтрана"
                v-model="selected_country"
                :items="countries"
                color="primary"
                item-title="fullName"
                item-value="country"
                variant="outlined"
                >
                <template v-slot:item="{ item, props }">
                    <v-list-item
                    v-bind="props"
                    :title="item.raw.fullName"
                    >
                    <template v-slot:prepend>
                        <img :src="`${fun.BASE_URL}/${item.raw.imageUrl || 'uploads/images/no-image.svg'}`" style="width: 40px; height: 30px; border-radius: 5px;" class="mr-3">
                    </template>
                    </v-list-item>
                </template>
                </v-autocomplete>
            </v-row>

            <div class="bg-primary rounded mt-1 mb-n2" style="height: 3px; width: 100%;"></div>

            <v-container class="ma-0 pa-0 mt-5 w-100" v-if="selected_country">
                <v-text-field variant="outlined" label="Искать сервисы" append-icon="mdi-magnify" v-model="search_services" density="comfortable"></v-text-field>
                <div v-if="filtered_services.length > 0" :style="`${mdAndDown ? 'max-height: 460px;' : ''} overflow-y: scroll;`" class="mt-n2">
                    <v-list>
                        <!--  v-for="item in items" :key="item.id" -->
                        <v-list-item v-for="(item, index) in filtered_services" :key="`${item}${index}`" :class="{ 'pa-0': md, 'py-0 pt-2': true }">
                            <div class="d-flex flex-row justify-space-between align-center">
                                <img :src="`${fun.BASE_URL}/${items[selected_country][item].imageUrl || 'uploads/images/no-image.svg'}`"
                                    style="height: 35px; width: 35px; border-radius: 5px;">

                                <div class="d-flex flex-row align-center ml-n10">
                                    <div :class="{ 'd-flex flex-column': true, 'ml-5': lgAndUp || smAndDown, 'ml-2': md }">
                                        <span class="font-weight-bold">{{ items[selected_country][item].fullName }}</span>
                                        <span class="font-weight-light mt-n1">Доступно - {{ items[selected_country][item].count }} шт</span>
                                    </div>

                                </div>

                                <div class="d-flex flex-column">
                                    <span class="font-weight-bold text-body-1">{{ items[selected_country][item].cost }} ₽</span>
                                </div>

                                <v-btn icon="mdi-cart-check" variant="text" density="compact" size="x-large" color="primary" title="Купить" class="pa-0 ma-0 rounded-pill cursor-pointer"
                                @click="create_order(item)">
                                </v-btn>
                            </div>
                            <v-divider v-if="index < filtered_services.length - 1" class="mt-2"></v-divider>
                        </v-list-item>

                    </v-list>
                </div>
                <p v-else class="text-center my-4">Нет доступных сервисов</p>
            </v-container>
            <v-container v-else>
                <label><p class="text-center">Выберите страну для просмотра доступных сервисов</p></label>
            </v-container>
        </v-card-text>
    </v-card>
</template>

<script setup>
import { useDisplay } from 'vuetify'

// Destructure only the keys you want to use
const { xs, sm, smAndDown, smAndUp, md, mdAndDown, mdAndUp, lg, lgAndDown, lgAndUp, xl, xlAndDown, xlAndUp, xxl } = useDisplay();
</script>

<script>
import * as fun from '@/functions.js'

export default {
    name: 'Services',
    data() {
        return {
            items: {},
            countries: [],
            selected_country: null,
            search_services: '',
        }
    },
    computed: {
        filtered_services(){
            return Object.keys(this.items).length > 0 ? Object.keys(this.items[this.selected_country]).filter((key) => {
                const substr = this.items[this.selected_country][key];
                const search = this.search_services.replace(/[^a-zA-Zа-яА-Я0-9]/g, '').toLowerCase();
                if(substr?.fullName){
                    return substr.fullName.replace(/[^a-zA-Zа-яА-Я0-9]/g, '').toLowerCase().includes(search)
                    || String(key).includes(search);
                }
                else return false;
            }) : [];
        },
        ordered_countries(){
            return this.countries.sort((a, b) => a.id < b.id ? -1 : a.id > b.id ? 1 : 0);
        }
    },
    watch: {
        search_services(new_val){
            this.filtered_services;
        },
    },
    methods: {
        create_order(service){
            fun.check_auth(this).then((auth) => {
                if(!auth) return this.$router.push('/auth/signin');
                this.axios({
                    method: 'post',
                    url: `${fun.SERVER_URL}/Orders`,
                    data: {
                        service: service,
                        country: this.selected_country,
                        operator: 'any',
                    }
                }).then((response) => {
                    fun.show(this, 'Заказ создан успешно!', true);
                    this.$emit('update_user');
                    this.$router.push('/orders');
                }).catch((err) => {
                    console.log(err);
                    fun.show(this, 'Ошибка создания заказа', false);
                });
            });
            
        },
        async fetchServices(){
            return this.axios({
                method: 'get',
                url: `${fun.SERVER_URL}/Services/priceinfo`
            }).then((response) => {
                const response_countries = response.data.countries;
                let temp_items = {};
                let temp_countries = [];
                for(let i = 0; i < response_countries.length; i++){
                    const current_country = response_countries[i];
                    temp_countries.push({
                        imageUrl: current_country.imagePath,
                        fullName: current_country.name,
                        country: current_country.shortName,
                    });

                    temp_items[current_country.shortName] = {};
                    for(let j = 0; j < current_country.services.length; j++){
                        const current_service = current_country.services[j];
                        temp_items[current_country.shortName][current_service.shortName] = {
                            imageUrl: current_service.imagePath,
                            fullName: current_service.name,
                            count: current_service.avalibleNumberCount,
                            cost: current_service.cost,
                        };
                    }
                }
                this.countries = temp_countries;
                this.selected_country = this.countries[0].country;
                this.items = temp_items;
            }).catch((err) => {
                console.log(err);
                fun.show(this, 'Ошибка при загрузке сервисов', false);
            });
        },

    },
    mounted(){
        this.fetchServices();
        setInterval(this.fetchServices, 20 * 1000);
    }
};
</script>

<style scoped>
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c5b7ed;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b39eef;
}
</style>