<template>
    <v-toolbar color="surface" class="pb-1" elevation="3">
        <v-spacer v-if="mdAndUp"></v-spacer>
        <div class="d-flex flex-row align-center">
            <!-- <v-toolbar-title><label class="text-primary font-weight-bold">Логотипчик</label></v-toolbar-title> -->
            <router-link to="/" :class="smAndDown ? 'ml-4' : ''"><img :src="require('@/assets/logo2.png')" style="max-height: 25px;" :class="smAndDown ? 'mt-3' : 'mt-4'"></router-link>
            
            <div v-if="mdAndUp">
                <nav class="d-flex flex-row mr-5 ml-5 mt-2" v-if="role_route == '/admin'">
                    <v-btn tile class="header--button--nav" :to="`/admin`"><p :class="{'font-weight-medium': current_path == '/admin', 'text-title': true}">Главная</p></v-btn>
                    <v-btn tile class="header--button--nav" :to="`/admin/users`"><p :class="{'font-weight-medium': current_path == '/admin/users', 'text-title': true}">Пользователи</p></v-btn>
                    <v-btn tile class="header--button--nav" :to="`/admin/services`"><p :class="{'font-weight-medium': current_path == '/admin/services', 'text-title': true}">Сервисы</p></v-btn>
                    <v-btn tile class="header--button--nav" :to="`/admin/operators`"><p :class="{'font-weight-medium': current_path == '/admin/operators', 'text-title': true}">Операторы</p></v-btn>
                    <v-btn tile class="header--button--nav" :to="`/admin/countries`"><p :class="{'font-weight-medium': current_path == '/admin/countries', 'text-title': true}">Страны</p></v-btn>
                    <v-btn tile class="header--button--nav" :to="`/admin/orders`"><p :class="{'font-weight-medium': current_path == '/admin/orders', 'text-title': true}">Заказы</p></v-btn>
                    <v-btn tile class="header--button--nav" :to="`/admin/news`"><p :class="{'font-weight-medium': current_path == '/admin/news', 'text-title': true}">Новости</p></v-btn>
                    <v-btn tile class="header--button--nav" :to="`/admin/payout`"><p :class="{'font-weight-medium': current_path == '/admin/payout', 'text-title': true}">Выплаты</p></v-btn>
                </nav>
                <nav class="d-flex flex-row mr-5 ml-5 mt-2" v-else-if="role_route == '/partner'">
                    <v-btn tile class="header--button--nav" :to="`/partner`"><p :class="{'font-weight-medium': current_path == '/partner', 'text-title': true}">Агенты</p></v-btn>
                    <v-btn tile class="header--button--nav" :to="`/partner/services`"><p :class="{'font-weight-medium': current_path == '/partner/services', 'text-title': true}">Сервисы</p></v-btn>
                    <v-btn tile class="header--button--nav" :to="`/partner/history`"><p :class="{'font-weight-medium': current_path == '/partner/history', 'text-title': true}">Заказы</p></v-btn>
                    <v-btn tile class="header--button--nav" :to="`/partner/finances`"><p :class="{'font-weight-medium': current_path == '/partner/finances', 'text-title': true}">Финансы</p></v-btn>
                </nav>
                <nav class="d-flex flex-row mr-5 ml-5 mt-2" v-else-if="role_route == '/'">
                    <v-btn tile class="header--button--nav" :to="`/`"><p :class="{'font-weight-medium': current_path == '/', 'text-title': true}">Главная</p></v-btn>
                    <v-btn tile class="header--button--nav" :to="`/orders`" v-if="login_successfull"><p :class="{'font-weight-medium': current_path == '/orders', 'text-title': true}">Заказы</p></v-btn>
                    <v-btn tile class="header--button--nav" :to="`/api`"><p :class="{'font-weight-medium': current_path == '/api', 'text-title': true}">API</p></v-btn>
                    <v-btn tile class="header--button--nav" :to="`/news`"><p :class="{'font-weight-medium': current_path == '/news', 'text-title': true}">Информация</p></v-btn>
                </nav>
            </div>
        </div>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <div v-if="login_successfull" class="d-flex flex-row align-center">
            <div class="text-subtitle-2 mt-1" v-if="mdAndUp">
                <p class="my-0 text-primary">{{userObject.email}}</p>
                <p class="my-0 mt-n1">Ваш баланс – <b>{{userObject.balance}} ₽</b></p>
            </div>
            <div class="text-subtitle-2 mt-1" v-else>
                <p class="my-0 text-body-1 font-weight-medium">{{userObject.balance}} ₽</p>
            </div>
            <v-menu v-if="mdAndUp" location="bottom">
                <template v-slot:activator="{ props }">
                    <v-app-bar-nav-icon v-bind="props" class="mt-1"></v-app-bar-nav-icon>
                </template>
                <v-list>
                    <v-list-item
                        append-icon="mdi-wallet"
                        to="/balance"
                    >
                        <v-list-item-title>Баланс</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                        append-icon="mdi-cog"
                        to="/settings"
                    >
                        <v-list-item-title>Настройки</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                        value="signout"
                        @click="signout()"
                    >
                        <v-list-item-title>Выход</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
            <div v-else>
                <v-app-bar-nav-icon @click="drawer = true;" color="primary"></v-app-bar-nav-icon>
            </div>
        </div>
        <div v-else>
            <v-btn color="primary" variant="flat" rounded to="/auth/signin" :class="{'mr-4': smAndDown}">Войти</v-btn>
        </div>
        <v-spacer v-if="mdAndUp"></v-spacer>
    </v-toolbar>

    <v-navigation-drawer
        v-if="smAndDown"
        v-model="drawer"
        :location="$vuetify.display.mobile ? 'right' : undefined"
    >
        <v-list>
            <v-list-item
                @click="drawer = false"
            >
                <template v-slot:prepend>
                    <v-icon color="primary">mdi-chevron-left</v-icon>
                </template>
                <v-list-item-title class="text-primary">Назад</v-list-item-title>
            </v-list-item>
            <v-divider class="mb-2"></v-divider>
            <div class="text-subtitle-2 ml-5" v-if="login_successfull">
                <p class="my-0">{{userObject.email}}</p>
                <p class="my-0 mt-n1">Ваш баланс – <b>{{userObject.balance}} ₽</b></p>
            </div>
            <v-divider class="mb-2" v-if="login_successfull"></v-divider>

            <div v-if="role_route == '/admin'">
                <v-list-item to="/admin">
                    <p :class="{'font-weight-medium': current_path == '/admin', 'text-title': true}">Главная</p>
                </v-list-item>
                <v-list-item to="/admin/users">
                    <p :class="{'font-weight-medium': current_path == '/admin/users', 'text-title': true}">Пользователи</p>
                </v-list-item>
                <v-list-item to="/admin/services">
                    <p :class="{'font-weight-medium': current_path == '/admin/services', 'text-title': true}">Сервисы</p>
                </v-list-item>
                <v-list-item to="/admin/operators">
                    <p :class="{'font-weight-medium': current_path == '/admin/operators', 'text-title': true}">Операторы</p>
                </v-list-item>
                <v-list-item to="/admin/countries">
                    <p :class="{'font-weight-medium': current_path == '/admin/countries', 'text-title': true}">Страны</p>
                </v-list-item>
                <v-list-item to="/admin/orders">
                    <p :class="{'font-weight-medium': current_path == '/admin/orders', 'text-title': true}">Заказы</p>
                </v-list-item>
                <v-list-item to="/admin/news">
                    <p :class="{'font-weight-medium': current_path == '/admin/news', 'text-title': true}">Новости</p>
                </v-list-item>
                <v-list-item to="/admin/payout">
                    <p :class="{'font-weight-medium': current_path == '/admin/payout', 'text-title': true}">Выплаты</p>
                </v-list-item>
            </div>
            <div v-else-if="role_route == '/partner'">
                <v-list-item to="/partner">
                    <p :class="{'font-weight-medium': current_path == '/partner', 'text-title': true}">Агенты</p>
                </v-list-item>
                <v-list-item to="/partner/services">
                    <p :class="{'font-weight-medium': current_path == '/partner/services', 'text-title': true}">Сервисы</p>
                </v-list-item>
                <v-list-item to="/partner/history">
                    <p :class="{'font-weight-medium': current_path == '/partner/history', 'text-title': true}">Заказы</p>
                </v-list-item>
                <v-list-item to="/partner/finances">
                    <p :class="{'font-weight-medium': current_path == '/partner/finance', 'text-title': true}">Финансы</p>
                </v-list-item>
            </div>
            <div v-else-if="role_route == '/'">
                <v-list-item to="/">
                    <p :class="{'font-weight-medium': current_path == '/', 'text-title': true}">Главная</p>
                </v-list-item>
                <v-list-item v-if="login_successfull" to="/orders">
                    <p :class="{'font-weight-medium': current_path == '/orders', 'text-title': true}">Заказы</p>
                </v-list-item>
                <v-list-item to="/api">
                    <p :class="{'font-weight-medium': current_path == '/api', 'text-title': true}">API</p>
                </v-list-item>
                <v-list-item to="/news">
                    <p :class="{'font-weight-medium': current_path == '/news', 'text-title': true}">Информация</p>
                </v-list-item>
            </div>
        
            <v-divider class="mt-2"></v-divider>
            <v-list-item
                append-icon="mdi-wallet"
                to="/balance"
            >
                <v-list-item-title>Баланс</v-list-item-title>
            </v-list-item>
            <v-list-item
                append-icon="mdi-cog"
                to="/settings"
            >
                <v-list-item-title>Настройки</v-list-item-title>
            </v-list-item>
            <v-list-item
                value="signout"
                @click="signout()"
            >
                <v-list-item-title>Выход</v-list-item-title>
            </v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>

<script setup>
import { useDisplay } from 'vuetify'

// Destructure only the keys you want to use
const { xs, sm, smAndDown, smAndUp, md, mdAndDown, mdAndUp, lg, lgAndDown, lgAndUp, xl, xlAndDown, xlAndUp, xxl } = useDisplay();
</script>

<script>
import * as fun from '@/functions.js'

export default {
    name: 'Toolbar',
    props: {
        login_successfull: {
            type: Boolean,
            default: false
        },
        userObject: {
            type: Object,
            default: {}
        }
    },
    data: () => {
        return {
            drawer: false,
        }
    },
    methods: {
        signout(){
            this.$cookies.keys().forEach(cookie => {
                this.$cookies.remove(cookie);
            })
            window.location.href = `${fun.HOST}/auth/signin`;
        },
    },
    computed: {
        current_path(){
            return this.$route.path;
        },
        role_route(){
            let route = "/";
            if(this.current_path.includes('/admin')) route = '/admin';
            if(this.current_path.includes('/partner')) route = '/partner';
            return route;
        },
    }
};
</script>

<style scoped>
nav a{
    text-decoration: none;
    margin-left: 15px;
}

.header--button--nav {
  text-transform: none !important;
  border-radius: 7px !important;
  background: white !important;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 5px;
}

.header--button--nav.v-btn--active {
  text-decoration: none;
  background: white !important;
  border: 2px solid #825bf3;
  color: #825bf3 !important;
}
</style>