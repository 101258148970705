<template>
  <v-container>
    <p class="text-h5 mb-4">Информация SMSBOX</p>
    <div class="news-container">
      <div
      v-for="(item) in items"
      :key="item.id"
      class="news-blockk"
      >
      <NewsBlock
      :news="item"
      ></NewsBlock>
      </div>
    </div>
    <div class="d-flex flex-row justify-center" v-if="loading">
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
  </v-container>
</template>

<script setup>
import { useDisplay } from "vuetify";

// Destructure only the keys you want to use
const {
  xs,
  sm,
  smAndDown,
  smAndUp,
  md,
  mdAndDown,
  mdAndUp,
  lg,
  lgAndDown,
  lgAndUp,
  xl,
  xlAndDown,
  xlAndUp,
  xxl,
} = useDisplay();
</script>

<script>
import * as fun from '@/functions.js'
import NewsBlock from '@/components/NewsBlock.vue'

export default {
  name: 'News',
  components: {
    NewsBlock,
  },
  data() {
    return {
      itemsPerPage: 10,
      page: 1,
      items: [],
      loading: false,
      totalItems: 0,
    }
  },
  computed: {
  },
  methods: {
    loadItems(){
      this.loading = true;
      this.axios({
        method: 'get',
        url: `${fun.SERVER_URL}/News`,
        params: {
          pageNumber: this.page,
          pageSize: this.itemsPerPage,
        },
      }).then((response) => {
        console.log(response);
        this.items = response.data.items;
        this.totalItems = response.data.totalCount;
        this.loading = false;
      }).catch((error) => {
        console.log(error);
        fun.show(this, "Произошла ошибка при загрузке данных");
        this.loading = false;
      });
    },
  },
  mounted() {
    this.loadItems();
  },
}
</script>

<style scoped>
.news-container{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 15px;
}
.news-blockk{
  /* border: 2px solid #825bf3; */
  border-radius: 5px;
}
</style>