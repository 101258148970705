<template>
    <v-footer class="transparent">
        <v-row class="d-flex flex-row justify-space-between" no-gutters>
            <v-spacer v-if="smAndUp"></v-spacer>
            <div :class="`my-2 ${xs ? 'ml-n4' : ''}`">
                <div v-for="(link, index) in links" :key="index">
                    <v-btn
                        :to="link.href"
                        color="black"
                        rounded="xl"
                        variant="text"
                    >
                        {{ link.name }}
                    </v-btn>
                </div>
            </div>
            <v-spacer v-if="smAndUp"></v-spacer>
            <div class="my-2">
                <p class="font-weight-medium">Контакты</p>
                <p>Telegram – <a :href="`https://t.me/${telegram}`" class="text-primary link">@{{telegram}}</a></p>
                <p>Email – <a :href="`mailto:${email}`" class="text-primary link">{{email}}</a></p>
            </div>
            <v-spacer v-if="smAndUp"></v-spacer>
            <v-col :class="`text-${xs ? 'left' : 'center'} mt-4`" cols="12">
                © {{ new Date().getFullYear() }} — <span class="font-weight-medium">Smsbox</span>
            </v-col>
        </v-row>
    </v-footer>
</template>

<script setup>
import { useDisplay } from 'vuetify'

// Destructure only the keys you want to use
const { xs, sm, smAndDown, smAndUp, md, mdAndDown, mdAndUp, lg, lgAndDown, lgAndUp, xl, xlAndDown, xlAndUp, xxl } = useDisplay();
</script>

<script>
import * as fun from '@/functions.js'

export default {
    name: 'Footer',
    data() {
        return {
           links: [
            { name: 'Партнерская программа', href: '/become-partner' },
            { name: 'Реферальная система', href: '/referral-program' },
            { name: 'Договор-оферта', href: '/offer' },
            { name: 'FAQ', href: '/faq' },
           ],
           telegram: 'smsbox_support',
           email: 'support@smsbox.store',
        }
    },
};
</script>

<style scoped>
.transparent{
    /* background-image: linear-gradient(to top, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0)); */
    background-color: rgba(255, 255, 255, 0.5);
    /* max-height: 100px; */
}
.link{
    text-decoration: none;
}
</style>