<template>
    <v-container fluid class="d-flex flex-row justify-center">
        <v-form
        @submit.prevent="confirm"
        :class="{'bg-surface elevation-3': true, 'pa-6 pb-3': smAndUp, 'pa-4 pb-1': xs, 'rounded': true}" :style="`width: ${xs ? '100vw' : '450px'};`">
            <p class="text-h5 mb-4">Подтвердите почту</p>
            <p class="text-body-1 text-center">Перейдите по ссылке в письме, отправленном на {{ user.email }}. После этого нажмите кнопку "Подтвердить".</p>
            <p class="text-subtitle-2 my-3 text-center">Не забудьте проверить папку Спам!</p>
            <v-btn type="submit" color="primary" block :disabled="confirm_disabled" @click="confirm()">Подтвердить</v-btn>

            <v-container class="d-flex flex-column align-center" fluid>
                <v-btn color="primary" style="text-decoration: dotted;" variant="text" block :disabled="button_disabled" @click="resend()">Отправить заново{{ timeout_started ? ` через ${timeout} сек.`: '' }}</v-btn>
                <p>Вернуться к <router-link to="/auth/signup" style="color: #825bf3; text-decoration: none;">регистрации</router-link></p>
            </v-container>
        </v-form>
    </v-container>
</template>


<script setup>
import { useDisplay } from 'vuetify'

// Destructure only the keys you want to use
const { xs, sm, smAndDown, smAndUp, md, mdAndDown, mdAndUp, lg, lgAndDown, lgAndUp, xl, xlAndDown, xlAndUp, xxl } = useDisplay();
</script>

<script>
import * as fun from '@/functions.js'

export default {
    name: 'SignIn',
    data() {
        return {
            email: '',
            password: '',
            button_disabled: false,
            timeout_started: false,
            confirm_disabled: false,
            timeout: 0,
        };
    },
    methods: {
        confirm() {
            this.confirm_disabled = true;
            this.axios({
                method: 'get',
                url: `${fun.SERVER_URL}/Users/info`,
            }).then((response) => {
                this.confirm_disabled = false;

                let userObject = response.data;
                if(userObject.emailConfirmed == true){
                    this.$cookies.set('user', JSON.stringify(userObject));
                    this.$router.go();
                } else {
                    fun.show(this, "Email не подтвержден");
                }
            }).catch((error) => {
                console.log(error)
                this.confirm_disabled = false;
                fun.show(this, 'Произошла ошибка');
            });
        },
        resend(){
            this.button_disabled = true;
            this.axios({
                method: 'post',
                url: `${fun.SERVER_URL}/Users/resendConfirmationEmail`,
                data: {
                    email: this.user.email,
                },
            }).then((response) => {
                fun.show(this, "Email был успешно отправлен", true);
                this.timeout = 60;
                const timeout_interval = setInterval(() => {
                    this.timeout--;
                    if(this.timeout == 0){
                        this.timeout_started = false;
                        this.button_disabled = false;
                        clearInterval(timeout_interval);
                    }
                }, 1000);
                this.timeout_started = true;
            }).catch((error) => {
                this.button_disabled = false;
                fun.show(this, 'Произошла ошибка');
            });
        },
    },
    computed: {
        user(){
            return (this.$cookies.get('user') && this.$cookies.get('user') != null) ? this.$cookies.get('user') : {};
        }
    },
    mounted() {
        // fun.check_auth(this).then(response => {
        //     if(response) this.$router.push('/')
        // })
        if(this.$cookies.get('user') && this.$cookies.get('user').emailConfirmed === true){
            this.$router.push('/');
        }
    },
};
</script>