const SERVER_URL = "https://app.smsbox.store/api";
const BASE_URL = "https://app.smsbox.store";
const WEBSOCKET_URL = "wss://app.smsbox.store";
const HOST = window.location.protocol + "//" + window.location.host;

const { useToast } = require("vue-toastification");
const toast = useToast();

function get_cookie(obj, key) {
  return obj.$cookies.get(key);
}

const toastConfig = {
  position: "top-right",
  timeout: 4000,
  closeOnClick: true,
  pauseOnFocusLoss: false,
  pauseOnHover: false,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false,
};

function show(obj, msg, status = false) {
  // obj.$notify({ type: status ? "success" : "error", text: msg });
  if (status) {
    toast.success(msg, toastConfig);
  } else {
    toast.error(msg, toastConfig);
  }
}

async function refresh(obj) {
  try {
    if (obj.$cookies.get("token") && obj.$cookies.get("token").refreshToken) {
      return new Promise((res, rej) => {
        fetch(`${SERVER_URL}/Users/refresh`, {
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
            'Authorization': undefined,
          },
          body: JSON.stringify({
            refreshToken: obj.$cookies.get("token").refreshToken,
          }),
        })
          .then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then(token_obj => {
            res();
            token_obj.updatedAt = new Date().getTime();
            obj.$cookies.set("token", JSON.stringify(token_obj));
            obj.axios.defaults.headers["Authorization"] = `${token_obj.tokenType} ${token_obj.accessToken}`;
          })
          .catch(error => {
            rej();
            console.error(error);
          });
      });
    }
  } catch (error) {
    console.log(error);
  }
}

async function check_auth(obj) {
  try {
    return (
      obj.$cookies.get("token")
      // &&
      // obj.$cookies.get("token").updatedAt +
      //   obj.$cookies.get("token").expiresIn * 1000 >=
      //   new Date().getTime()
    );
  } catch (error) {
    console.log(error);
  }
}

function format_current_date(dateString) {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear().toString().slice(-2);
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");

  return `${day}.${month}.${year} ${hours}:${minutes}`;
}

async function copy_text(text) {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      show(this, "Скопировано в буфер обмена", true);
    })
    .catch((error) => {
      console.log(error);
      show(this, "Произошла ошибка");
    });
}

function formatDateWithTimezone(dateString) {
  const userDate = new Date();
  const offsetHours = userDate.getTimezoneOffset() / -60;

  const date = new Date(dateString);
  const utc = date.getTime() + date.getTimezoneOffset() * 60000;
  const newDate = new Date(utc + 3600000 * offsetHours);

  const day = String(newDate.getDate()).padStart(2, "0");
  const month = String(newDate.getMonth() + 1).padStart(2, "0");
  const year = newDate.getFullYear();
  const hours = String(newDate.getHours()).padStart(2, "0");
  const minutes = String(newDate.getMinutes()).padStart(2, "0");

  return `${day}.${month}.${year} ${hours}:${minutes}`;
}

function convertToGMTString(dateString) {
  const userDate = new Date(dateString);
  const gmtDateString = userDate.toISOString();
  console.log(gmtDateString)
  return gmtDateString;
}

module.exports = {
  SERVER_URL,
  BASE_URL,
  WEBSOCKET_URL,
  HOST,
  get_cookie,
  show,
  refresh,
  check_auth,
  format_current_date,
  copy_text,
  formatDateWithTimezone,
  convertToGMTString,
};
