<template>
    <v-container class="d-flex flex-row justify-space-around flex-wrap pa-0 ma-0 rounded" style="border: grey solid 2px;">
        <v-col :cols="smAndDown ? 12 : 6">
            <div class="d-flex flex-row justify-space-around align-center">
                <p class="text-h6">API Key</p>
                <div style="width: 70%;" class="d-flex flex-row" @click="fun.copy_text(String(userObj.apiKey))">
                    <input style="width: 80%;" :value="userObj.apiKey" disabled class="ml-2">
                    <v-btn icon="mdi-content-copy" variant="text" title="Скопировать"></v-btn>
                </div>
            </div>
        </v-col>
        <v-col :cols="smAndDown ? 12 : 6">
            <div :class="`d-flex flex-row align-center ${smAndDown ? 'justify-center' : 'justify-end'}`">
                <v-btn prepend-icon="mdi-reload" elevation="0" color="primary" @click="regenerate()" class="mt-1">Сгенерировать повторно</v-btn>
            </div>
        </v-col>
    </v-container>

    
    <v-row class="d-flex flex-row justify-space-around flex-wrap pa-0 ma-0 rounded">
        <v-col :cols="smAndDown ? 12 : 6">
            <v-card>
                <v-card-title>Ваши данные</v-card-title>
                <v-card-text>
                    <p class="text-body-1">Логин:<br><span class="font-weight-medium">{{ userObj.login }}</span></p>
                    <p class="text-body-1 mt-2">Email:<br><span class="font-weight-medium">{{ userObj.email }}</span></p>
                    <p class="text-body-1 mt-2">ID в системе:<br><span class="font-weight-medium">{{ userObj.id }}</span></p>

                    <div v-if="userObj.roles && (userObj.roles.includes('Administrator') || userObj.roles.includes('Partner'))" class="mt-4">
                        <v-btn v-if="userObj.roles && userObj.roles.includes('Administrator')" color="primary" to="/admin" block variant="flat">Войти в админ-панель</v-btn>
                        <v-btn v-if="userObj.roles && userObj.roles.includes('Partner')" color="primary" to="/partner" block variant="flat" class="mt-2">Войти в кабинет партнера</v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-col>

        <v-col :cols="smAndDown ? 12 : 6">
            <v-card>
                <v-form autocomplete="off" @submit.prevent="change_password">
                    <v-card-title>Смена пароля</v-card-title>
                    <v-card-text>
                        <v-text-field v-model="oldPass" label="Старый пароль" type="password" variant="outlined" name="old_pass"></v-text-field>
                        <v-text-field v-model="newPass" label="Новый пароль" type="password" variant="outlined" :rules="[
                            v => !!v || 'Пароль обязателен',
                            v => (v && v.length >= 6) || 'Пароль должен содержать не менее 6 символов',
                            v => /\d/.test(v) || 'Пароль должен содержать хотя бы одну цифру',
                            v => /[a-z]/.test(v) || 'Пароль должен содержать хотя бы одну прописную латинскую букву',
                            v => /[A-Z]/.test(v) || 'Пароль должен содержать хотя бы одну заглавную латинскую букву',
                            v => /\W/.test(v) || 'Пароль должен содержать хотя бы один символ, не являющийся буквой или цифрой',
                        ]"></v-text-field>
                        <v-text-field v-model="newPass2" label="Повторите новый пароль" type="password" variant="outlined" :rules="[
                            v => !!v || 'Подтверждение пароля обязательно',
                            v => (v == newPass) || 'Пароли должны совпадать',
                        ]"></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn type="submit" color="greeny" variant="flat" block :disabled="is_change_password_btn_disabled || change_password_button_disabled" class="green_btn"><p class="text-white">Изменить</p></v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-col>
    </v-row>
</template>

<script setup>
import { useDisplay } from 'vuetify'

// Destructure only the keys you want to use
const { xs, sm, smAndDown, smAndUp, md, mdAndDown, mdAndUp, lg, lgAndDown, lgAndUp, xl, xlAndDown, xlAndUp, xxl } = useDisplay();
</script>

<script>
import * as fun from '@/functions.js'

export default {
  name: 'Settings',
  data() {
    return {
        userObj: {},
        oldPass: "",
        newPass: "",
        newPass2: "",
        change_password_button_disabled: false,
    }
  },
  methods: {
    getUserObj(){
        try{
            this.userObj = this.$cookies.get('user');
        } catch(e){
            fun.show(this, "Ошибка при получении данных");
        }
    },
    regenerate(){
        this.axios({
            method: 'put',
            url: `${fun.SERVER_URL}/Users/apiKey/${this.userObj.id}`,
        }).then(async (response) => {
            fun.show(this, "Новый ключ был успешно сгенерирован", true);

            const userObject = (await this.axios({
                method: 'get',
                url: `${fun.SERVER_URL}/Users/info`,
            })).data;
            this.$cookies.set('user', JSON.stringify(userObject));
            this.getUserObj();

        }).catch((error) => {
            console.log(error);
            fun.show(this, "Ошибка при генерации ключа");
        });
    },
    change_password(){
        this.change_password_button_disabled = true;
        this.axios({
            method: 'post',
            url: `${fun.SERVER_URL}/Users/manage/info`,
            data: {
                newEmail: this.userObj.email,
                newPassword: this.newPass,
                oldPassword: this.oldPass,
            }
        }).then(async (response) => {
            this.oldPass = "";
            this.newPass = "";
            this.newPass2 = "";
            this.change_password_button_disabled = false;
            fun.show(this, "Пароль был успешно обновлен", true);

            const userObject = (await this.axios({
                method: 'get',
                url: `${fun.SERVER_URL}/Users/info`,
            })).data;
            this.$cookies.set('user', JSON.stringify(userObject));
            this.getUserObj();

        }).catch((error) => {
            this.change_password_button_disabled = false;
            console.log(error);
            fun.show(this, "Произошла ошибка, проверьте ваши данные");
        });
    },
    become_partner(){
        // this.axios({
        //     method: 'post',
        //     url: `${fun.SERVER_URL}/Agent`,
        //     data: {
        //         login: this.userObj.login,
        //     }
        // }).then((response) => {
        //     console.log(response.data);
        //     fun.show(this, "Вы стали партнером", true);
        // }).catch((err) => {
        //     console.log(err);
        //     fun.show(this, "Произошла ошибка");
        // });
    },
  },
  mounted(){
    this.getUserObj();
  },
  computed: {
    is_change_password_btn_disabled(){
        let result = this.oldPass == '' || this.newPass == '' || this.newPass2 == '';
        if(!result){
            let v = this.newPass;
            let password_correct = (v && v.length >= 6) && /\d/.test(v) && /[a-z]/.test(v) && /[A-Z]/.test(v) && /\W/.test(v);
            let passwords_match = this.newPass == this.newPass2;
            result = !password_correct || !passwords_match;
        }
        return result;
    }
  }
}
</script>

<style scoped lang="scss">
.green_btn:disabled {
  background-color: greeny !important;
  color: #ffffff !important;
}
</style>