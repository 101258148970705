<template>
  <!-- <div v-for="item in Object.keys(news)" class="news-block">
    {{ item }}: {{ news[item] }}
  </div> -->
  <!-- <div class="pa-2 cursor-pointer" @click="opened = true;">
    <p class="text-h5">{{ news.title }}</p>
    <p>{{ text.substring(0, 100) + (text.length > 100 ? '...' : '') }}</p>
    <p class="text-right text-body-2 text-grey-darken-2" :title="created">{{ created.substring(0, 10) }}</p>
  </div> -->
  <v-card
    class="mx-auto elevation-2"
    @click="opened = true;"
  >
    <template v-slot:title>
      <span class="font-weight-black">{{ news.title }}</span>
    </template>
    <template v-slot:subtitle>
      <span class="font-weight-black" :title="created">{{ created.substring(0, 10) }}</span>
    </template>

    <v-card-text class="bg-surface-light pt-4">
      {{ text.substring(0, 100) + (text.length > 100 ? '...' : '') }}
    </v-card-text>
  </v-card>

  <v-overlay
    v-model="opened"
    class="align-center justify-center"
  >
    <v-card style="min-width: 300px; max-width: 800px;">
      <v-card-title>{{ news.title }}</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <div v-html="htmlFromMarkdown" class="mb-2 news_text_wrapper" style="max-width: 100%;"></div>
        <div class="d-flex flex-row justify-space-between">
          <p class="text-body-2 text-grey-darken-2" :title="created">Опубликовано {{ created.substring(0, 10) }}</p>
          <p v-if="created != lastModified" class="text-body-2 text-grey-darken-2 ml-6" :title="lastModified">Изменено {{ lastModified.substring(0, 10) }}</p>
        </div>
      </v-card-text>
      <v-card-actions class="bg-primary cursor-pointer" @click="opened = false;">
        <v-btn block class="ma-0">Прочитано</v-btn>
      </v-card-actions>
    </v-card>
  </v-overlay>
</template>

<script setup>
import { useDisplay } from "vuetify";

// Destructure only the keys you want to use
const {
  xs,
  sm,
  smAndDown,
  smAndUp,
  md,
  mdAndDown,
  mdAndUp,
  lg,
  lgAndDown,
  lgAndUp,
  xl,
  xlAndDown,
  xlAndUp,
  xxl,
} = useDisplay();
</script>

<script>
import * as fun from "@/functions.js";
import { marked } from 'marked';
import removeMarkdown from "markdown-to-text";

export default {
  name: "NewsBlock",
  props: {
    news: {
      type: Object,
    },
  },
  data: () => {
    return {
      opened: false,
      htmlFromMarkdown: null,
      text: '',
      created: '',
      lastModified: '',
    };
  },
  methods: {
  },
  mounted(){
    this.htmlFromMarkdown = marked.parse(this.news.description);
    this.text = removeMarkdown(this.news.description);
    this.created = fun.formatDateWithTimezone(this.news.created);
    this.lastModified = fun.formatDateWithTimezone(this.news.lastModified);
  },
};
</script>

<style scoped>
.news_text_wrapper{
  padding-left: 10px;
  padding-right: 10px;
}
</style>