<template>
  <v-data-table-server
    v-model:items-per-page="itemsPerPage"
    :headers="headers"
    :items="serverItems"
    :items-length="totalItems"
    :loading="loading"
    :search="search"
    loading-text="Загружаем данные..."
    item-value="name"
    @update:options="loadItems"
  >
    <template v-slot:item.balance="{ item }">
      <p>{{ item.balance }} ₽</p>
    </template>
    <template v-slot:item.targetRoles="{ item }">
      <p><span v-for="(it, index) in item.targetRoles" :key="index">{{it}}{{ index + 1 == item.roles.length ? '' : ', ' }}</span></p>
    </template>
    <template v-slot:item.isBlocked="{ item }">
      <v-chip
        :color="item.isBlocked ? 'red' : 'green'"
        :text="item.isBlocked ? 'Да' : 'Нет'"
        class="text-uppercase"
        size="small"
        label
      ></v-chip>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-btn color="orange" variant="tonal" prepend-icon="mdi-pencil" class="ma-1" @click="open_edit_form(item.id)">Редактировать</v-btn>
      <v-btn color="red" variant="tonal" prepend-icon="mdi-delete" class="ma-1" @click="delete_service(item.id)">Удалить</v-btn>
    </template>
  </v-data-table-server>

  <v-overlay
    v-model="circle_loading"
    class="align-center justify-center"
    persistent
  >
    <v-progress-circular
      :size="50"
      color="primary"
      indeterminate
    ></v-progress-circular>
  </v-overlay>
</template>

<script>
import * as fun from '@/functions.js'
import Card from '@/components/Card.vue'

export default {
  name: 'AdminPayout',
  components: {
    Card,
  },
  data() {
    return {
      itemsPerPage: 10,
      headers: [
        { title: 'ID', key: 'id' },
        { title: 'Система', value: 'paymentSystem' },
        { title: 'Сумма', value: 'totalAmount' },
        { title: 'Сумма с комиссией', value: 'amountAfterFee' },
        { title: 'Действия', value: 'direction' },
        { title: 'Действия', value: 'status' },
        { title: 'Действия', value: 'additionalInfo' },
        { title: 'Действия', value: 'created' },
      ],
      serverItems: [],
      loading: true,
      totalItems: 0,
    }
  },
  methods: {
    loadItems({ page, itemsPerPage, sortBy } = {page: 1, itemsPerPage: this.itemsPerPage}){
      this.loading = true;
      this.axios({
        method: 'get',
        url: `${fun.SERVER_URL}/Payout`,
        params: {
          pageNumber: page,
          pageSize: itemsPerPage,
        },
      }).then((response) => {
        this.serverItems = response.data.items;
        this.totalItems = response.data.totalCount;
        this.loading = false;
      }).catch((error) => {
        fun.show(this, "Произошла ошибка при загрузке данных")
        this.loading = false;
      });
    },
    
  },
  mounted(){
  },
}
</script>