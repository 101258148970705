<template>
  <v-container fluid class="container">
    <p class="text-h5 mb-3 text-uppercase text-center">Добро пожаловать на smsbox.store!</p>
    <p class="text-body-1 text-center mx-auto" style="max-width: 600px;">Наша платформа предлагает аренду виртуальных номеров для получения SMS и автоматическую обработку сообщений. С нами вам не потребуется покупать физические SIM-карты. Мы предоставляем номера исключительно вам — повторная продажа исключена.</p>
    
    <div class="divider-line"></div>

    <p class="text-h5 text-uppercase text-center">Наши преимущества</p>
    <div class="mt-0 w-100 d-flex flex-row align-center justify-space-between advantages_row" style="max-width: 700px; margin-left: auto; margin-right: auto;">
      <div class="d-flex flex-column align-center">
        <img :src="require('@/assets/icons/automatization.svg')" width="50" height="50">
        <p class="text-body-1 mt-2 text-center">Полная автоматизация</p>
      </div>
      <div class="d-flex flex-column align-center">
        <img :src="require('@/assets/icons/fast_delivery.svg')" width="50" height="50">
        <p class="text-body-1 mt-2 text-center">Быстрая активация</p>
      </div>
      <div class="d-flex flex-column align-center">
        <img :src="require('@/assets/icons/operators_support.svg')" width="50" height="50">
        <p class="text-body-1 mt-2 text-center">Широкая поддержка операторов</p>
      </div>
    </div>
    <div class="mt-3 w-100 d-flex flex-row align-center justify-space-between advantages_row" style="max-width: 700px; margin-left: auto; margin-right: auto;">
      <div class="d-flex flex-column align-center">
        <img :src="require('@/assets/icons/high_reliability.svg')" width="50" height="50">
        <p class="text-body-1 mt-2 text-center">Высокая надежность</p>
      </div>
      <div class="d-flex flex-column align-center">
        <img :src="require('@/assets/icons/useful_api.svg')" width="50" height="50">
        <p class="text-body-1 mt-2 text-center">Удобный API</p>
      </div>
      <div class="d-flex flex-column align-center">
        <img :src="require('@/assets/icons/confidentiality.svg')" width="50" height="50">
        <p class="text-body-1 mt-2 text-center">{{xs ? 'Конфиден-циальность' : 'Конфиденциальность'}}</p>
      </div>
    </div>

    <div class="divider-line"></div>

    <p class="text-h5 mb-3 text-uppercase text-center">Где можно использовать наши услуги?</p>
    <div class="mt-3 w-100 d-flex flex-row align-center justify-space-between advantages_row" style="max-width: 700px; margin-left: auto; margin-right: auto;">
      <div class="d-flex flex-column align-center">
        <img :src="require('@/assets/icons/social_media.svg')" width="50" height="50">
        <p class="text-body-1 mt-2 text-center">Социальные сети</p>
      </div>
      <div class="d-flex flex-column align-center">
        <img :src="require('@/assets/icons/messengers.svg')" width="50" height="50">
        <p class="text-body-1 mt-2 text-center">Мессенджеры</p>
      </div>
      <div class="d-flex flex-column align-center">
        <img :src="require('@/assets/icons/online_services.svg')" width="50" height="50">
        <p class="text-body-1 mt-2 text-center">Онлайн-сервисы</p>
      </div>
    </div>

    <div class="divider-line"></div>

    <p class="text-h5 mb-3 text-uppercase text-center">Инструкция по использованию</p>
    <div class="instructions_container" style="max-width: 700px; margin-left: auto; margin-right: auto;">
      <div class="d-flex flex-row align-center">
        <p class="text-h3 font-weight-bold text-secondary">1</p>
        <p class="text-body-1 ml-4">Регистрируемся на сайте: Создайте учетную запись на smsbox.store, чтобы начать работу</p>
      </div>
      <div class="d-flex flex-row align-center">
        <p class="text-h3 font-weight-bold text-secondary">2</p>
        <p class="text-body-1 ml-4">Пополняем баланс: Внесите средства на ваш счет для использования услуг.</p>
      </div>
      <div class="d-flex flex-row align-center">
        <p class="text-h3 font-weight-bold text-secondary">3</p>
        <p class="text-body-1 ml-4">Выбираем сервис: Выберите нужный вам сервис из списка доступных и нажмите "Купить".</p>
      </div>
      <div class="d-flex flex-row align-center">
        <p class="text-h3 font-weight-bold text-secondary">4</p>
        <p class="text-body-1 ml-4">Вводим номер: Введите выданный вам номер телефона в поле, где требуется принять SMS.</p>
      </div>
      <div class="d-flex flex-row align-center">
        <p class="text-h3 font-weight-bold text-secondary">5</p>
        <p class="text-body-1 ml-4">Ждем SMS:Дождитесь получения SMS на выданный номер (обычно это занимает от 2 до 20 секунд).</p>
      </div>
      <div class="d-flex flex-row align-center">
        <p class="text-h3 font-weight-bold text-secondary">6</p>
        <p class="text-body-1 ml-4">Заканчиваем работу: Заканчивайте работу с номером, либо при необходимости принимайте SMS еще раз, нажав на "Повтор".</p>
      </div>
    </div>
  </v-container>
</template>

<script setup>
import { useDisplay } from "vuetify";

// Destructure only the keys you want to use
const {
  xs,
  sm,
  smAndDown,
  smAndUp,
  md,
  mdAndDown,
  mdAndUp,
  lg,
  lgAndDown,
  lgAndUp,
  xl,
  xlAndDown,
  xlAndUp,
  xxl,
} = useDisplay();
</script>

<script>
import * as fun from '@/functions.js'

export default {
  name: 'Main',
  data() {
    return {
    }
  },
}
</script>

<style scoped>
.container *{
  margin-bottom: 5px;
}
.container *:last-child{
  margin-bottom: 0;
}
.divider-line{
  width: 100%;
  height: 1px;
  background-color: black;
  margin-top: 15px;
  margin-bottom: 15px;
}
.advantages_row>div{
  width: 30%;
}
</style>
