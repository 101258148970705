<template>
  <v-text-field v-model="search" label="Поиск по id или email" @change="loadItems"></v-text-field>
  <v-data-table-server
    v-model:items-per-page="itemsPerPage"
    :headers="headers"
    :items="serverItems"
    :items-length="totalItems"
    :loading="loading"
    :search="search"
    loading-text="Загружаем данные..."
    item-value="name"
    @update:options="loadItems"
  >
    <template v-slot:item.balance="{ item }">
      <p>{{ item.balance }} ₽</p>
    </template>
    <template v-slot:item.roles="{ item }">
      <p><span v-for="(it, index) in item.roles" :key="index">{{it}}{{ index + 1 == item.roles.length ? '' : ', ' }}</span></p>
    </template>
    <template v-slot:item.is_partner="{ item }">
      <v-checkbox label="Является партнером" class="mb-n5" style="min-width: 220px;" v-model="item.is_partner" :disabled="item.partner_updating" @update:model-value="change_partner(item)"></v-checkbox>
    </template>
    <template v-slot:item.isBlocked="{ item }">
      <v-chip
        :color="item.isBlocked ? 'red' : 'green'"
        :text="item.isBlocked ? 'Да' : 'Нет'"
        class="text-uppercase"
        size="small"
        label
      ></v-chip>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-btn v-if="item.isBlocked" color="green" @click="unlock(item.id)" variant="tonal" prepend-icon="mdi-lock">Разблокировать</v-btn>
      <v-btn v-else color="red" @click="lockout(item.id)" variant="tonal" prepend-icon="mdi-lock-open-variant">Заблокировать</v-btn>
    </template>
  </v-data-table-server>
</template>

<script>
import * as fun from '@/functions.js'

export default {
  name: 'AdminUsers',
  data() {
    return {
      itemsPerPage: 10,
      headers: [
        { title: 'ID', key: 'id' },
        { title: 'Email', key: 'email' },
        { title: 'Логин', key: 'login' },
        { title: 'Баланс', key: 'balance' },
        { title: 'Ключ API', key: 'apiKey' },
        { title: 'Роли', key: 'roles' },
        { title: 'Партнер', key: 'is_partner' },
        { title: 'IP', key: 'ipAddress' },
        { title: 'Заблокирован', key: 'isBlocked' },
        { title: 'Действия', key: 'actions' },
      ],
      search: '',
      serverItems: [],
      loading: true,
      totalItems: 0,
    }
  },
  methods: {
    loadItems({ page, itemsPerPage, sortBy }){
      this.loading = true;
      this.axios({
        method: 'get',
        url: `${fun.SERVER_URL}/Users`,
        params: {
          pageNumber: page,
          pageSize: itemsPerPage,
          SearchTerm: this.search,
        },
      }).then((response) => {
        this.serverItems = response.data.items.map((item) => { return {...item, is_partner: item.roles.includes('Partner')} });
        this.totalItems = response.data.totalCount;
        this.loading = false;
      }).catch((error) => {
        fun.show(this, "Произошла ошибка при загрузке данных")
        this.loading = false;
      });
    },
    unlock(id){
      this.loading = true;
      this.axios({
        method: 'put',
        url: `${fun.SERVER_URL}/Users/unblock/${id}`,
      }).then((response) => {
        this.loading = false;
        for(let i = 0; i < this.serverItems.length; i++) {
          if(this.serverItems[i].id == id) {
            this.serverItems[i].isBlocked = !(this.serverItems[i].isBlocked);
            break;
          }
        }
      }).catch((error) => {
        this.loading = false;
        fun.show(this, "Произошла ошибка");
      });
    },
    lockout(id){
      this.loading = true;
      this.axios({
        method: 'put',
        url: `${fun.SERVER_URL}/Users/block/${id}`,
      }).then((response) => {
        this.loading = false;
        for(let i = 0; i < this.serverItems.length; i++) {
          if(this.serverItems[i].id == id) {
            this.serverItems[i].isBlocked = !(this.serverItems[i].isBlocked);
            break;
          }
        }
      }).catch((error) => {
        this.loading = false;
        fun.show(this, "Произошла ошибка");
      });
    },
    change_partner(item){
      item.partner_updating = true;
      if(!item.is_partner) this.delete_partner(item);
      else this.make_partner(item);
    },
    make_partner(item){
      this.axios({
        method: 'post',
        url: `${fun.SERVER_URL}/Partners`,
        data: {
          userId: item.id,
        }
      }).then((response) => {
        if(item.roles == null) item.roles = [];
        item.roles.push("Partner");
      }).catch((err) => {
        console.log(err);
        item.is_partner = false;
        fun.show(this, "Произошла ошибка");
      }).finally(() => {
        item.partner_updating = false;
      });
    },
    delete_partner(item){
      this.axios({
        method: 'delete',
        url: `${fun.SERVER_URL}/Partners/${item.id}`,
      }).then((response) => {
        if(item.roles == null) item.roles = [];
        item.roles = item.roles.filter(role => role != "Partner");
      }).catch((err) => {
        console.log(err);
        item.is_partner = true;
        fun.show(this, "Произошла ошибка");
      }).finally(() => {
        item.partner_updating = false;
      });
    },
  },
  mounted(){
  },
}
</script>