<template>
    <v-card :style="`width: ${xs ? '90vw' : '500px'}; max-height: 90vh; overflow-y: auto;`">
      <v-card-title>{{ title }}</v-card-title>
      <v-card-text>
        <slot></slot>
      </v-card-text>
      <v-card-actions>
        <v-btn variant="text" @click="close();">Отмена</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="confirm()">Готово</v-btn>
      </v-card-actions>
    </v-card>
</template>

<script setup>
import { useDisplay } from 'vuetify'

// Destructure only the keys you want to use
const { xs, sm, smAndDown, smAndUp, md, mdAndDown, mdAndUp, lg, lgAndDown, lgAndUp, xl, xlAndDown, xlAndUp, xxl } = useDisplay();
</script>

<script>
import * as fun from '@/functions.js'

export default {
    name: 'Card',
    props: {
        title: {
            type: String,
            default: ""
        },
    },
    methods: {
        close() {
            this.$emit('closed');
        },
        confirm() {
            this.$emit('confirmed');
        }
    }
};
</script>