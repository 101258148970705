<template>
  <v-container :class="`${xs ? 'pa-0' : ''}`">
    <v-row class="d-flex flex-row align-center">
      <v-col :cols="smAndDown ? 7 : 9">
        <div class="w-100">
          <p class="mb-1">Сортировать по времени: </p>
          <VueDatePicker v-model="date_range" range @update:model-value="handleDate"></VueDatePicker>
        </div>
      </v-col>
      <v-col :cols="smAndDown ? 5 : 3">
        <v-switch
        v-model="active_orders"
        :label="active_orders ? 'Активые заказы' : 'Все заказы'"
        inset
        color="primary"
        class="mb-n12"
        ></v-switch>
      </v-col>
    </v-row>
  </v-container>

  <v-data-table-server
    v-if="active_orders"
    v-model:items-per-page="itemsPerPage"
    v-model:page="page"
    :headers="headers"
    :items="serverItems"
    :items-length="totalItems"
    :loading="loading"
    loading-text="Загружаем данные..."
    @update:options="loadActiveItems"
  >

    <template v-slot:item.id="{ item }">
      <v-row class="d-flex flex-row align-center">
        <img :src="`${fun.BASE_URL}/${item.serviceImage ? item.serviceImage : ''}`" style="max-height: 50px;">
        <p><b>{{ item.serviceName }}</b></p>
      </v-row>
    </template>
    <template v-slot:item.countryShortName="{ item }">
      <v-row class="d-flex flex-row align-center">
        <img :src="`${fun.BASE_URL}/${item.countryImage ? item.countryImage : ''}`" style="max-height: 50px;">
        <p>{{ item.countryShortName.toUpperCase() }}</p>
      </v-row>
    </template>
    <template v-slot:item.created="{ item }">
      <p>{{ formatDateWithTimezone(item.created) }}</p>
    </template>
    <template v-slot:item.activationStatus="{ item }">
      <v-chip
        v-if="item.activationStatus == 1"
        color="orange"
        text="Готов"
        class="text-uppercase"
        size="small"
        label
      ></v-chip>
      <v-chip
        v-else-if="item.activationStatus == 3"
        color="orange"
        text="Повтор запроса"
        class="text-uppercase"
        size="small"
        label
      ></v-chip>
      <v-chip
        v-else-if="item.activationStatus == 6"
        color="green"
        text="Успешно"
        class="text-uppercase"
        size="small"
        label
      ></v-chip>
      <v-chip
        v-else-if="item.activationStatus == -1"
        color="red"
        text="Отмена"
        class="text-uppercase"
        size="small"
        label
      ></v-chip>
      <v-chip
        v-else-if="item.activationStatus == 8"
        color="red"
        text="Заблокирован"
        class="text-uppercase"
        size="small"
        label
      ></v-chip>
    </template>
    <template v-slot:item.price="{ item }">
      <p>{{ item.price }} ₽</p>
    </template>
  </v-data-table-server>

  <v-data-table-server
    v-else
    v-model:items-per-page="itemsPerPage"
    v-model:page="page"
    :headers="headers"
    :items="serverItems"
    :items-length="totalItems"
    :loading="loading"
    loading-text="Загружаем данные..."
    @update:options="loadItems"
  >

    <template v-slot:item.id="{ item }">
      <v-row class="d-flex flex-row align-center">
        <img :src="`${fun.BASE_URL}/${item.serviceImage ? item.serviceImage : ''}`" style="max-height: 50px;">
        <p><b>{{ item.serviceName }}</b></p>
      </v-row>
    </template>
    <template v-slot:item.countryShortName="{ item }">
      <v-row class="d-flex flex-row align-center">
        <img :src="`${fun.BASE_URL}/${item.countryImage ? item.countryImage : ''}`" style="max-height: 50px;">
        <p>{{ item.countryShortName.toUpperCase() }}</p>
      </v-row>
    </template>
    <template v-slot:item.created="{ item }">
      <p>{{ formatDateWithTimezone(item.created) }}</p>
    </template>
    <template v-slot:item.activationStatus="{ item }">
      <v-chip
        v-if="item.activationStatus == 1"
        color="orange"
        text="Готов"
        class="text-uppercase"
        size="small"
        label
      ></v-chip>
      <v-chip
        v-else-if="item.activationStatus == 3"
        color="orange"
        text="Повтор запроса"
        class="text-uppercase"
        size="small"
        label
      ></v-chip>
      <v-chip
        v-else-if="item.activationStatus == 6"
        color="green"
        text="Успешно"
        class="text-uppercase"
        size="small"
        label
      ></v-chip>
      <v-chip
        v-else-if="item.activationStatus == -1"
        color="red"
        text="Отмена"
        class="text-uppercase"
        size="small"
        label
      ></v-chip>
      <v-chip
        v-else-if="item.activationStatus == 8"
        color="red"
        text="Заблокирован"
        class="text-uppercase"
        size="small"
        label
      ></v-chip>
    </template>
    <template v-slot:item.price="{ item }">
      <p>{{ item.price }} ₽</p>
    </template>
  </v-data-table-server>
</template>
<script setup>
import { useDisplay } from "vuetify";

// Destructure only the keys you want to use
const {
  xs,
  sm,
  smAndDown,
  smAndUp,
  md,
  mdAndDown,
  mdAndUp,
  lg,
  lgAndDown,
  lgAndUp,
  xl,
  xlAndDown,
  xlAndUp,
  xxl,
} = useDisplay();
</script>

<script>
import * as fun from '@/functions.js'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

export default {
  name: 'PartnerHistory',
  components: {
    VueDatePicker,
  },
  data() {
    return {
      itemsPerPage: 10,
      page: 1,
      headers: [
        { title: 'Сервис', key: 'id' },
        { title: 'Страна', key: 'countryShortName' },
        { title: 'Номер', key: 'phoneNumber' },
        { title: 'Дата', key: 'created' },
        { title: 'Статус', key: 'activationStatus' },
        { title: 'Цена', key: 'price' },
      ],
      serverItems: [],
      loading: false,
      totalItems: 0,
      date_range: null,
      active_orders: false,
    }
  },
  methods: {
    loadItems({ page, itemsPerPage, sortBy }, dates = {} ){
      this.loading = true;
      this.axios({
        method: 'get',
        url: `${fun.SERVER_URL}/Partners/orders`,
        params: {
          pageNumber: page,
          pageSize: itemsPerPage,
          createdFrom: dates.createdFrom || undefined,
          createdTo: dates.createdTo || undefined,
        },
      }).then((response) => {
        this.serverItems = response.data.items;
        this.totalItems = response.data.totalCount;
        this.loading = false;
      }).catch((error) => {
        fun.show(this, "Произошла ошибка при загрузке данных")
        this.loading = false;
      });
    },
    loadActiveItems({ page, itemsPerPage, sortBy }, dates = {} ){
      this.loading = true;
      this.axios({
        method: 'get',
        url: `${fun.SERVER_URL}/Partners/activeOrders`,
        params: {
          pageNumber: page,
          pageSize: itemsPerPage,
          createdFrom: dates.createdFrom || undefined,
          createdTo: dates.createdTo || undefined,
        },
      }).then((response) => {
        this.serverItems = response.data.items;
        this.totalItems = response.data.totalCount;
        this.loading = false;
      }).catch((error) => {
        fun.show(this, "Произошла ошибка при загрузке данных")
        this.loading = false;
      });
    },
    formatDateWithTimezone(date){
      return fun.formatDateWithTimezone(date);
    },
    handleDate(date){
      if(date?.length == 2){
        if(this.active_orders){
          this.page = 1;
          this.loadActiveItems({ page: 1, itemsPerPage: this.itemsPerPage }, { createdFrom: fun.convertToGMTString(date[0]), createdTo: fun.convertToGMTString(date[1]) })
        }
        else{
          console.log(fun.convertToGMTString(date[0]));
          this.page = 1;
          this.loadItems({ page: 1, itemsPerPage: this.itemsPerPage }, { createdFrom: fun.convertToGMTString(date[0]), createdTo: fun.convertToGMTString(date[1]) })
        }
      }
      else{
        this.page = 1;
        this.loadItems({ page: 1, itemsPerPage: this.itemsPerPage });
      }
    },
  },
  mounted(){
  },
}
</script>

<style>
.dp__theme_light {
    --dp-background-color: #fff;
    --dp-text-color: #212121;
    --dp-hover-color: #f3f3f3;
    --dp-hover-text-color: #212121;
    --dp-hover-icon-color: #959595;
    --dp-primary-color: #f25c2b;
    --dp-primary-disabled-color: #dd7f5f;
    --dp-primary-text-color: #f8f5f5;
    --dp-secondary-color: #c0c4cc;
    --dp-border-color: #ddd;
    --dp-menu-border-color: #ddd;
    --dp-border-color-hover: #aaaeb7;
    --dp-border-color-focus: #aaaeb7;
    --dp-disabled-color: #f6f6f6;
    --dp-scroll-bar-background: #f3f3f3;
    --dp-scroll-bar-color: #959595;
    --dp-success-color: #76d275;
    --dp-success-color-disabled: #a3d9b1;
    --dp-icon-color: #959595;
    --dp-danger-color: #ff6f60;
    --dp-marker-color: #ff6f60;
    --dp-tooltip-color: #fafafa;
    --dp-disabled-color-text: #8e8e8e;
    --dp-highlight-color: rgb(25 118 210 / 10%);
    --dp-range-between-dates-background-color: var(--dp-hover-color, #f3f3f3);
    --dp-range-between-dates-text-color: var(--dp-hover-text-color, #212121);
    --dp-range-between-border-color: var(--dp-hover-color, #f3f3f3);
}
</style>