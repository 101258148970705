<template>
    <v-container fluid class="d-flex flex-row justify-center">
        <v-stepper
        fluid
        alt-labels
        non-linear
        flat
        v-model="step"
        hide-actions
        class=" elevation-3"
        >
            <v-stepper-header class="elevation-0" style="display: none;">
                <v-stepper-item :complete="step > 0" class="text-body-2 px-2" color="primary"
                title="Введите email" value="1" />
                <v-stepper-item :complete="step > 1" class="text-body-2 px-2" color="primary"
                title="Сбросьте пароль" value="2" />
            </v-stepper-header>

            <v-stepper-window fluid>
                <v-stepper-window-item value="1" fluid>
                    <v-form
                    @submit.prevent="send_code"
                    :class="{'bg-surface': true, 'pa-6 pb-3': smAndUp, 'pa-4 pb-1': xs, 'rounded': true}" :style="`width: ${xs ? '100vw' : '450px'};`">
                        <p class="text-h5 mb-4">Восстановление пароля</p>
                        <p class="text-body-1 text-center mb-2">Мы отправим код подтверждения для сброса пароля на указанную почту</p>
                        <v-text-field v-model="email" label="Email" type="email" required :rules="[v => !!v || 'Email обязателен', v => /.+@.+\..+/.test(v) || 'Email должен быть валидным']"></v-text-field>
                        <v-btn type="submit" color="primary" block :disabled="is_btn_disabled || button_disabled">Отправить код</v-btn>

                        <v-container class="d-flex flex-column align-center" fluid>
                            <p>Вернуться к <router-link to="/auth/signin" style="color: #825bf3; text-decoration: none;">авторизации</router-link></p>
                        </v-container>
                    </v-form>
                </v-stepper-window-item>

                <v-stepper-window-item value="2" fluid>
                    <v-form
                    @submit.prevent="reset_password"
                    :class="{'bg-surface': true, 'pa-6 pb-3': smAndUp, 'pa-4 pb-1': xs, 'rounded': true}" :style="`width: ${xs ? '100vw' : '450px'};`">
                        <p class="text-h5 mb-4">Сбросить пароль</p>
                        <p class="text-body-1 text-left mb-2">Введите код, отправленный на {{ maskEmail(email) }}:</p>
                        <v-text-field v-model="code" label="Код" required :rules="[v => !!v || 'Код обязателен',]"></v-text-field>

                        <p class="text-body-1 text-left mb-2">Установите новый пароль:</p>
                        <v-text-field v-model="password" label="Новый пароль" type="password" required :rules="[
                            v => !!v || 'Пароль обязателен',
                            v => (v && v.length >= 6) || 'Пароль должен содержать не менее 6 символов',
                            v => /\d/.test(v) || 'Пароль должен содержать хотя бы одну цифру',
                            v => /[a-z]/.test(v) || 'Пароль должен содержать хотя бы одну прописную латинскую букву',
                            v => /[A-Z]/.test(v) || 'Пароль должен содержать хотя бы одну заглавную латинскую букву',
                            v => /\W/.test(v) || 'Пароль должен содержать хотя бы один символ, не являющийся буквой или цифрой',
                        ]"></v-text-field>
                        <v-text-field v-model="password2" label="Повторите новый пароль" type="password" required :rules="[
                            v => !!v || 'Подтверждение пароля обязательно',
                            v => (v == password) || 'Пароли должны совпадать',
                        ]"></v-text-field>

                        <v-btn type="submit" color="primary" block :disabled="is_reset_btn_disabled || reset_button_disabled">Подтвердить</v-btn>

                        <v-container class="d-flex flex-column align-center" fluid>
                            <p>Вернуться к <span style="color: #825bf3; text-decoration: none; cursor: pointer;r" @click="go_back()">предыдущему шагу</span></p>
                        </v-container>
                    </v-form>
                </v-stepper-window-item>
            </v-stepper-window>
        </v-stepper>
        
    </v-container>
</template>


<script setup>
import { useDisplay } from 'vuetify'

// Destructure only the keys you want to use
const { xs, sm, smAndDown, smAndUp, md, mdAndDown, mdAndUp, lg, lgAndDown, lgAndUp, xl, xlAndDown, xlAndUp, xxl } = useDisplay();
</script>

<script>
import * as fun from '@/functions.js'

export default {
    name: 'ForgotPassword',
    data() {
        return {
            step: 0,
            email: '',
            button_disabled: false,
            reset_button_disabled: false,

            code: '',
            password: '',
            password2: '',
        };
    },
    methods: {
        send_code() {
            this.button_disabled = true;
            this.axios({
                method: 'post',
                url: `${fun.SERVER_URL}/Users/forgotPassword`,
                data: {
                    email: this.email,
                }
            }).then((response) => {
                this.step += 1;
            }).catch((error) => {
                console.log(error)
                fun.show(this, 'Введены некорректные данные');
            }).finally(() => {
                this.button_disabled = false;
            });
        },
        reset_password() {
            this.reset_button_disabled = true;
            this.axios({
                method: 'post',
                url: `${fun.SERVER_URL}/Users/resetPassword`,
                data: {
                    email: this.email,
                    resetCode: this.code,
                    newPassword: this.password,
                }
            }).then((response) => {
                fun.show(this, 'Новый пароль был успешно установлен', true);
                this.$router.push('/auth/signin');
            }).catch((error) => {
                console.log(error)
                fun.show(this, 'Произошла ошибка, проверьте данные');
            }).finally(() => {
                this.reset_button_disabled = false;
            });
            
        },
        go_back() {
            this.step = this.step - (this.step <= 0 ? 0 : 1);
        },
        maskEmail(email) {
            try{
                const [name, domain] = email.split('@');
                const maskedName = name.length > 2 ? name.substring(0, 2) + '*'.repeat(name.length - 2) : name;
                return maskedName + '@' + domain;
            } catch (e) {
                return email;
            }
        },
    },
    computed: {
        is_btn_disabled(){
            return this.email == '' || !(/.+@.+\..+/.test(String(this.email)));
        },
        is_reset_btn_disabled(){
            let result = this.email == '' || this.code == '' || this.password == '' || this.password2 == '';
            if(!result){
                let v = this.password;
                let password_correct = (v && v.length >= 6) && /\d/.test(v) && /[a-z]/.test(v) && /[A-Z]/.test(v) && /\W/.test(v);
                let passwords_match = this.password == this.password2;
                let email_correct = /.+@.+\..+/.test(this.email);
                result = !password_correct || !passwords_match || !email_correct;
            }
            return result;
        },
    },
    mounted() {
    },
};
</script>

<style scoped>
.v-stepper:deep(.v-stepper-window) {
  margin: 0;
}
</style>