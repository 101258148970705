// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'


const myTheme = {
  dark: false,
  colors: {
    background: '#fbfbfb',
    surface: '#FFFFFF',
    primary: '#825bf3',
    secondary: '#EE7B68',
    greeny: '#22D372',
    title: '#001531',
  },
  variables: {
    'border-color': '#f25c2b',
  }
}

export default createVuetify({
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
  display: {
    thresholds: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1850,
      xxl: 2560,
    }
  },
  theme: {
    defaultTheme: 'myTheme',
    themes: {
      myTheme
    }
  },
  defaults: {
    VDataTable: {
      itemsPerPageOptions: [10, 25, 50, 100],
    },
    VDataTableServer: {
      itemsPerPageOptions: [10, 25, 50, 100],
    },
  },
})
