import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueCookies from 'vue-cookies';
import Notifications from '@kyvg/vue3-notification' // https://kyvg.github.io/vue3-notification/guide/installation.html
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import * as fun from '@/functions.js'

import VMdEditor from '@kangc/v-md-editor';
import '@kangc/v-md-editor/lib/style/base-editor.css';
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js';
import '@kangc/v-md-editor/lib/theme/style/vuepress.css';
// Prism
import Prism from 'prismjs';
import 'prismjs/components/prism-json';
VMdEditor.use(vuepressTheme, {
  Prism,
});
import ruRU from '@kangc/v-md-editor/lib/lang/ru-RU';
VMdEditor.lang.use('ru-RU', ruRU);

loadFonts()
/* eslint-disable */

const app = createApp(App);
app.use(VueCookies);

app.$cookies.config('400d');
app.use(router)

const isValidJSON = v => {
  return typeof v === 'object' && v != null;
};


const token_obj = isValidJSON(app.$cookies.get('token')) ? app.$cookies.get('token') : {'tokenType': '', 'accessToken' : '', 'expiresIn': -1, 'refreshToken' : ''};
axios.defaults.headers['Authorization'] = (token_obj.tokenType != '' && token_obj.accessToken != '') ? `${token_obj.tokenType} ${token_obj.accessToken}` : '';
app.config.globalProperties.axios = axios;

app.config.globalProperties.axios_errors_amount = 0;

let refreshAttempts = 0; // Счетчик попыток обновления токена
const MAX_REFRESH_ATTEMPTS = 2; // Максимальное количество попыток

axios.interceptors.response.use(response => {
  return response;
}, async (error) => {
  if (error.response?.status === 401 || true) {
    if (refreshAttempts >= MAX_REFRESH_ATTEMPTS) {
      return Promise.reject(error);
    }
    refreshAttempts++;
    
    try {
      await fun.refresh({ $router: app.$router, $cookies: app.$cookies, axios: axios });
      const conf = error.config;
      const token_obj = isValidJSON(app.$cookies.get('token')) ? app.$cookies.get('token') : {'tokenType': '', 'accessToken': '', 'expiresIn': -1, 'refreshToken': ''};
      conf.headers['Authorization'] = `${token_obj.tokenType} ${token_obj.accessToken}`;
      
      return axios(conf);
    } catch (e) {
      // app.$cookies.keys().forEach(cookie => {
      //   app.$cookies.remove(cookie);
      // });
      app.$router.push('/auth/signin');
      window.location.href = `${fun.HOST}/auth/signin`;
      return Promise.reject(e);
    }
  } else {
    return Promise.reject(error);
  }
});


app.use(vuetify)
  .use(Toast)
  .use(Notifications)
  .use(VMdEditor)
  .mount('#app');
